import { CustomInput, CustomTextArea } from "../../../components/Form/Inputs/Inputs";
import React, { Component } from "react";
import {
  addProduct,
  getAllDiscounts,
  getAllProductCategory,
  getAllProductFilterCategories,
  getAllSizes,
} from "../../../functions/postFunctions";

import { CustomButton } from "../../../components/Buttons/Button";
import SearchableDropdown from "../../../components/Form/Dropdown/SearchableDropdown";
import SortableGrid from "../../../components/SortableGrid/SortableGrid";
import TextEditor from "../../../components/TextEditor/TextEditor";
import { addProductValidation } from "../../../functions/validateFunctions";
import { createSlug } from "../../../functions/functions";
import SearchableDropdownMultiple from "../../../components/Form/Dropdown/SearchableDropdownMultiple";

import Carousel from 'react-elastic-carousel';


class AddProduct extends Component {
  state = {
    language_id: 1,
    name: "",
    description: "",
    content: "",
    product_code: "",
    date: new Date(),
    content_right: "",
    errors: [],
    showErrorPopup: false,
    showWarningPopup: false,
    images: null,
    active: "",
    listOfImages: [],
    stepOne: false,
    edit: false,
    completed: 0,
    showUploadPopup: false,
    uploadComplete: false,
    rightSide: false,
    categoryList: [],
    category_id: 0,
    amount: "",
    price: "",
    unit: 0,
    sizes: [
      {
        size: "",
        amount: "",
      },
    ],
    allSizes: [],
    specifications: [],
    discounts: [],
    discount: { value: 0, label: "Bez popusta" },
    filters: [],
    filterOptions: [],
    tempUrl: [],
    previewModal: false,
  };

  addSpecification = () => {
    const specification = { label: "", value: "", product_id: this.state.id };

    this.setState(({ specifications }) => ({
      specifications: [...specifications, specification],
    }));
  };

  deleteSpecification = (index) => {
    const specifications = this.state.specifications.filter((_, i) => i !== index);
    this.setState({ specifications: [] }, () => this.setState({ specifications }));
  };

  componentDidMount() {
    this.props.setBreadcrumbs("Dodaj proizvod");
    let categories = [];

    const handleRecursion = (arr) => {
      arr.forEach((e) => {
        categories.push({ value: e.id, label: e.name });
        if (e.children.length > 0) {
          handleRecursion(e.children);
        }
      });
    };

    getAllProductCategory().then((res) => {
      if (res.success) {
        handleRecursion(res.categories);
        const currentCategory = categories.find((e) => e.value === 0);
        this.setState({ categoryList: categories, currentCategory });
      }
    });

    getAllDiscounts().then((res) => {
      if (res.success) {
        const discounts = res.discounts.map((e) => ({
          value: e.id,
          label: `${e.name} (${e.discount}%)`,
        }));
        const discount = discounts.find((e) => e.value === this.state.discount.value);
        discounts.unshift({ value: 0, label: "Bez popusta" });
        this.setState({
          discounts,
          discount: discount || { value: 0, label: "Bez popusta" },
        });
      }
    });

    getAllProductFilterCategories().then((res) => {
      if (res.success) {
        const filterOptions = [];
        res.categories.forEach((e) => {
          filterOptions.push(
            ...e.filters.map((el) => ({ label: `${e.name} - ${el.name}`, value: el.id }))
          );
        });
        this.setState({ filterOptions });
      }
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.errors != this.state.errors && this.state.errors.length > 0) {
      this.setState({ showErrorPopup: true });
    }
    if (prevState.stepOne !== this.state.stepOne) {
      const item = document.querySelector(".gallery");
      this.navigationMove(item, "gallery");
    }
  }

  handleInput = (name, value, index, i) => {
    if (name === "size-" || name === "amount-") {
      this.setState(({ sizes }) => ({
        sizes: [
          ...sizes.slice(0, index),
          {
            ...sizes[index],
            [name.replace("-", "")]: value,
          },
          ...sizes.slice(index + 1),
        ],
      }));
    } else if (name === "filter") {
      const ind = typeof index === "number" ? index : i;
      this.setState(({ filters }) => ({
        filters: [
          ...filters.slice(0, ind),
          {
            ...filters[ind],
            id: typeof value === "number" ? value : value.value,
          },
          ...filters.slice(ind + 1),
        ],
      }));
    } else if (name === "value" || name === "label") {
      this.setState(({ specifications }) => ({
        specifications: [
          ...specifications.slice(0, index),
          {
            ...specifications[index],
            [name]: value,
          },
          ...specifications.slice(index + 1),
        ],
      }));
    } else if (name === "description") {
      
      this.setState({ description: value.srcElement.innerText });
    } else {
      this.setState({ [name]: value });
    }
  };

  handleFileInput = (event) => {
    this.setState({ images: [...event] });

    let tempString = [];

    for(let i=0; i<event.length; i++){
      tempString.push(URL.createObjectURL(event[i]))
    }

    tempString.map(e => {
      this.setState(prevState => ({
        tempUrl: [...prevState.tempUrl, e]
      }))
    })
  };

  showModal = () => {
    this.setState({ previewModal: !this.state.previewModal});
    
  }

  removePopup = () => {
    this.setState({ showErrorPopup: false, showWarningPopup: false });
  };

  handleSubmit = (event) => {
    const { images, stepOne } = this.state;
    event.preventDefault();
    const errors = addProductValidation(this.state);

    if (errors.length === 0) {
      if (!stepOne) {
        this.setState({ stepOne: true, active: "gallery" });
      } else {
        if (!images || images.length === 0) {
          this.setState({ previewModal: false})
          this.setState({ showWarningPopup: true });
        } else {
          this.setState({ previewModal: false})
          this.createPage();
        }
      }
    } else {
      this.setState({ previewModal: false})
      this.setState({ errors });
    }
  };

  createPage = () => {
    const {
      images,
      category_id,
      name,
      amount,
      unit,
      price,
      product_code,
      description,
      sizes,
      specifications,
      filters,
    } = this.state;
    const data = new FormData();

    if (images) {
      images.forEach((e) => {
        data.append("images[]", e);
      });
    }
    const items = {
      category_id,
      name,
      amount,
      unit,
      price,
      product_code,
      description,
      sizes: JSON.stringify(sizes),
      specifications: JSON.stringify(specifications),
      filters: JSON.stringify(filters.filter((e) => e.id !== 0)),
    };

    Object.keys(items).forEach((e) => {
      data.append(e, items[e]);
    });
    this.setState({ showUploadPopup: true });
    const config = {
      onUploadProgress: (progressEvent) => {
        const completed = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        this.setState({
          completed,
          uploadComplete: completed === 100,
        });
      },
    };
    addProduct(data, this.props.token, config).then((res) => {
      if (res.success) {
        this.props.history.push(`/proizvodi/${localStorage.getItem("page")}`);
      } else {
        this.setState({ showErrorPopup: true });
      }
    });
  };

  underlineRef = React.createRef();

  navigationMove = (item, active) => {
    item.parentElement.querySelectorAll(".link").forEach((e) => {
      e.classList.remove("text-color-primary");
      e.classList.remove("text-color-font-l");
    });
    item.classList.add("text-color-primary");
    this.underlineRef.current.style.transform = `translateX(${item.offsetLeft}px)`;
    this.setState({ active });
  };

  toggleRightSideContent = () => {
    this.setState(({ rightSide }) => ({
      rightSide: !rightSide,
    }));
  };

  handleAddSize = () => {
    this.setState(({ sizes }) => ({
      sizes: [...sizes, { amount: "", size: "" }],
    }));
  };

  handleRemoveSize = (index) => {
    const sizes = this.state.sizes.filter((_, i) => i !== index);
    this.setState({ sizes: [] }, () => this.setState({ sizes }));
  };

  handleAddFilter = () => {
    this.setState(({ filters }) => ({
      filters: [...filters, { id: 0 }],
    }));
  };

  handleRemoveFilter = (index) => {
    const filters = this.state.filters.filter((_, i) => i !== index);
    this.setState({ filters: [] }, () => this.setState({ filters }));
  };

  render() {
    const { categoryList, allSizes, discounts } = this.state;
    const units = [
      { value: 0, label: "Komad" },
      { value: 1, label: "Kutija" },
    ];
    return (
      <div className='wrapper'>
        <div className={`errorPopup ${this.state.showWarningPopup ? "popupShown" : ""}`}>
          <div className='content py-20 px-10 d-flex justify-content-between flex-column'>
            <h3 className='f-s-22 f-w-4 uppercase text-center text-color-red'>Upozorenje</h3>
            <h5 className='f-s-16 f-w-4 uppercase text-center'>
              Nijedna fotografija nije selektovana. Ukoliko nastavite default-na cover fotografija
              će biti korištena
            </h5>
            <div className='button text-center mt-30'>
              <CustomButton className='mr-10 red' onClick={this.createPage}>
                Nastavi
              </CustomButton>
              <CustomButton onClick={this.removePopup}>Zatvori</CustomButton>
            </div>
          </div>
        </div>
        <div className={`errorPopup ${this.state.showUploadPopup ? "popupShown" : ""}`}>
          <div className='content py-20 px-10 d-flex justify-content-center flex-column'>
            <h3 className='f-s-22 f-w-4 uppercase text-center text-color-primary'>
              {!this.state.uploadComplete ? "Upload u toku" : "Optimizacija u toku"}
            </h3>
            {!this.state.uploadComplete ? (
              <div className='uploadBar d-flex align-items-center p-r'>
                <div className='barWrapper'>
                  <span
                    className='bar d-flex align-items-center justify-content-center'
                    style={{ width: `${this.state.completed}%` }}
                  />
                </div>
                <span className='outerProgress f-s-16 center-a-b text-color-primary'>
                  {this.state.completed}%
                </span>
              </div>
            ) : (
              <div className='optimization loader d-flex justify-content-center align-items-center'>
                <div className='fancy-spinner'>
                  <div className='ring' />
                  <div className='ring' />
                  <div className='dot' />
                </div>
              </div>
            )}
          </div>
        </div>
        {this.state.stepOne && (
          <div className='innerNavigation pb-10 mb-40'>
            <span
              className='f-s-16 mr-20 uppercase text-color-primary link'
              onClick={(e) => this.navigationMove(e.currentTarget, "content")}>
              Sadržaj
            </span>
            <span
              className='f-s-16 uppercase text-color-font-l link gallery'
              onClick={(e) => this.navigationMove(e.currentTarget, "gallery")}>
              Galerija
            </span>
            <span className='indicator' ref={this.underlineRef} />
          </div>
        )}
        {!this.state.stepOne || this.state.active === "content" ? (
          <div className='contentWrapper'>
            <div className={` errorPopup ${this.state.showErrorPopup ? "popupShown" : ""}`}>
              <div className='content py-20 px-10 d-flex justify-content-between flex-column'>
                <h3 className='f-s-18 f-w-4 uppercase text-center'>
                  Problemi prilikom kreiranja proizvoda
                </h3>
                <ul className='mt-30'>
                  {this.state.errors.map((e, index) => {
                    return (
                      <li key={index} className='f-s-16 text-center'>
                        {e}
                      </li>
                    );
                  })}
                </ul>
                <div className='button text-center mt-30'>
                  <CustomButton onClick={this.removePopup}>Zatvori</CustomButton>
                </div>
              </div>
            </div>
            <form onSubmit={(e) => e.preventDefault()}>
              <h2 className='uppercase f-s-20 text-color-primary f-w-4'>Crnogorski</h2>
              <div className='row mt-20'>
                <div className='col-md-4'>
                  <CustomInput
                    label='Naziv'
                    value={this.state.name}
                    handleChange={this.handleInput}
                    name='name'
                    index={0}
                  />
                </div>

                <div className='col-md-4'>
                  <SearchableDropdown
                    data={categoryList}
                    placeholder='Kategorija'
                    name='category_id'
                    handleChange={this.handleInput}
                  />
                </div>
                <div className='col-md-4'>
                  <CustomInput
                    label='Šifra proizvoda'
                    value={this.state.product_code}
                    handleChange={this.handleInput}
                    name='product_code'
                    index={0}
                  />
                </div>
              </div>

              <div className='row mt-30'>
                <div className='col-md-3'>
                  <SearchableDropdown
                    data={units}
                    placeholder='Jedinica mjere'
                    name='unit'
                    handleChange={this.handleInput}
                  />
                </div>
                <div className='col-md-3'>
                  <CustomInput
                    value={this.state.amount}
                    label='Stanje'
                    name='amount'
                    handleChange={this.handleInput}
                  />
                </div>
                <div className='col-md-3'>
                  <CustomInput
                    label='Cijena'
                    value={this.state.price}
                    handleChange={this.handleInput}
                    name='price'
                    index={0}
                    type='number'
                  />
                </div>
                <div className='col-md-3'>
                  <SearchableDropdown
                    data={discounts}
                    placeholder='Popust'
                    name='discount'
                    handleChange={this.handleInput}
                    value={this.state.discount}
                  />
                </div>
              </div>
              <div className='row mt-30'>
                <div className='col-md-3'>
                  <CustomButton onClick={this.handleAddSize}>Dodaj veličinu</CustomButton>
                </div>
              </div>
              <div className='row pt-15 pb-30 mt-10 borders'>
                {this.state.sizes.map((e, index) => (
                  <div className='col-md-6 mt-15' key={index}>
                    <div className='row'>
                      <div className='col-md-4'>
                        <CustomInput
                          label='Veličina'
                          name='size-'
                          handleChange={this.handleInput}
                          index={index}
                          value={e.size}
                        />
                      </div>
                      <div className='col-md-4'>
                        <CustomInput
                          label='Količina'
                          value={e.amount}
                          handleChange={this.handleInput}
                          name='amount-'
                          index={index}
                          type='number'
                        />
                      </div>
                      <div className='col-md-3'>
                        <CustomButton className='red' onClick={() => this.handleRemoveSize(index)}>
                          X
                        </CustomButton>
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              {/* asdasdasd */}
              <div className='row mt-30'>
                <div className='col-md-3'>
                  <CustomButton onClick={this.handleAddFilter}>Dodaj filter</CustomButton>
                </div>
              </div>
              <div className='row pt-15 pb-30 mt-10 borders'>
                {this.state.filters.map((e, index) => (
                  <div className='col-md-4 mt-15' key={index}>
                    <div className='row'>
                      <div className='col-md-9'>
                        <SearchableDropdownMultiple
                          value={this.state.filterOptions.find((el) => el.value === e.id)}
                          data={this.state.filterOptions}
                          placeholder='Filter'
                          name='filter'
                          handleChange={this.handleInput}
                          index={index}
                        />
                      </div>
                      <div className='col-md-3'>
                        <CustomButton
                          className='red'
                          onClick={() => this.handleRemoveFilter(index)}>
                          X
                        </CustomButton>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              {/* /asdasdasd */}

              <div className='row mt-30'>
                <div className='col-12'>
                  <TextEditor
                    name='description'
                    handleInput={this.handleInput}
                    index={0}
                    value={this.state.description}
                  />
                </div>
              </div>
              {this.state.specifications.map((e, index) => {
                return (
                  <div className='row mt-30' key={index}>
                    <div className='col-md-5'>
                      <CustomInput
                        label='Naziv specifikacije'
                        value={e.label}
                        handleChange={this.handleInput}
                        name='label'
                        index={index}
                      />
                    </div>
                    <div className='col-md-5'>
                      <CustomInput
                        label='Opis specifikacije'
                        value={e.value}
                        handleChange={this.handleInput}
                        name='value'
                        index={index}
                      />
                    </div>
                    <div className='col-md-2'>
                      <CustomButton
                        onClick={(e) => this.deleteSpecification(index)}
                        className='mr-20 red'>
                        Izbriši
                      </CustomButton>
                    </div>
                  </div>
                );
              })}
              <div className='row mt-30'>
                <div className='col-12'>
                  <CustomButton onClick={this.addSpecification} className='mr-20 green'>
                    Dodaj specifikaciju
                  </CustomButton>
                </div>
              </div>
            </form>
          </div>
        ) : (
          <div className='galleryWrapper'>
            <CustomInput
              label='Dodaj fotografije'
              value={null}
              handleChange={this.handleFileInput}
              name='file[]'
              index={0}
              type='file'
              multiple
            />
            {this.state.images ? (
              <div className='selectedImages my-20 py-10 px-15'>
                <h3 className='f-s-16 text-color-primary f-w-4'>Fotografije odabrane za upload:</h3>
                <ul className='mt-20'>
                  {this.state.images.map((e, index) => {
                    return (
                      <li key={index} className='f-s-12'>
                        {index + 1}. {e.name}
                      </li>
                    );
                  })}
                </ul>
              </div>
            ) : (
              ""
            )}
            <SortableGrid images={this.state.listOfImages} />
          </div>
        )}

            <div
              className={`addTeamModal d-flex justify-content-center align-items-center ${
              this.state.previewModal ? "visible" : ""
            }`} style={{overflowY: 'auto'}}
            > 
              <div className="addTeamContent py-30 px-30">
                <div  style={{display: 'flex', flexWrap: 'wrap', justifyContent:'space-around', width: "80%"}}>
              
              
                  {this.state.tempUrl.length !== 0 ? 
                    <Carousel style={{ width: '350px', height: '200px', marginTop: '75px'}}>
                    {this.state.tempUrl.map((e, index) => ( 
                          <div key={index} className="img-fluid">
                            <img src={e} alt="temp"  height='200' width='350'/>
                          </div>
                    ))}
                    </Carousel> 
                    :
                    <div style={{ width: '350px', height: '200px', marginTop: '75px'}}>
                      <img src='data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%22286%22%20height%3D%22180%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20286%20180%22%20preserveAspectRatio%3D%22none%22%3E%3Cdefs%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%23holder_177d8c2276e%20text%20%7B%20fill%3Argba(255%2C255%2C255%2C.75)%3Bfont-weight%3Anormal%3Bfont-family%3AHelvetica%2C%20monospace%3Bfont-size%3A14pt%20%7D%20%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cg%20id%3D%22holder_177d8c2276e%22%3E%3Crect%20width%3D%22286%22%20height%3D%22180%22%20fill%3D%22%23777%22%3E%3C%2Frect%3E%3Cg%3E%3Ctext%20x%3D%22107.1875%22%20y%3D%2296.24375%22%3E286x180%3C%2Ftext%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E'
                            alt="temp"  className="img-fluid"  height='200' width='350'/>
                    </div> }
                

                    <div>            
                      <h1 style={{color: '#00AEEF', fontSize: '34px'}}>{this.state.name}</h1>
                      <p style={{fontSize: '14px', marginBottom: '10px'}}>Šifra proizvoda: {this.state.product_code}</p>

                      <div style={{ background: '#D7DF21', width: '70px', height: '5px', borderRadius: '1px' }}></div>

                      <h3  style={{color: '#00AEEF',  fontSize: '28px',marginTop: '15px', marginBottom: '15px'}}>{this.state.price}€</h3>

                      <div style={{ background: '#D7DF21', width: '70px', height: '5px', borderRadius: '1px' }}></div>

                      {this.state.sizes[0].size !== '' ? 
                        <div>
                          <p style={{color: '#58585A',fontSize: '18px'}}>Dostupne veličine:</p><br></br>
                          <div> 
                      {this.state.sizes.map((e, index) => ( 
                         <p key={index} style={{marginRight: "10px",border: '2px solid  #58585A', display: 'inline-block', padding: '10px', fontWeight: '700', fontSize: '16px'}}>
                           {e.size}
                          </p>
                      ))}
                          </div>
                        </div> : null
                      }
                    </div>
                  </div>

                  {this.state.description ? 
                  <div style={{ margin: '30px', fontSize: '16px'}}>
                    <strong style={{color: '#00AEEF'}}>Opis :</strong> <br></br>
                    {this.state.description}
                  </div> : null  } 



                  {this.state.specifications.length !== 0 ?
                  <div style={{ margin: '30px', fontSize: '16px'}}> 
                     <strong style={{color: '#00AEEF'}}>Specifikacije: </strong>
                      <table >

                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Naziv</th>
                              <th>Opis</th>
                            </tr>
                          </thead>

                          <tbody>
                        { this.state.specifications.map((e, index) => 

                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{e.label}</td>
                                <td>{e.value}</td>
                            </tr>

                        ) }
                          </tbody>
                        </table>
                  </div>
                    : null }
      
                  <div className="row mt-50">
                    <div className="col-lg-12 d-flex justify-content-center">
                      <CustomButton
                        className="mr-30"
                        onClick={this.handleSubmit}
                      >
                        Kreiraj proizvod
                      </CustomButton>
                      <CustomButton className="red" onClick={this.showModal}>
                        Odustani
                      </CustomButton>
                    </div>
                  </div>

                </div>
              </div>

        <div className='row mt-30'>
          <div className='col-12 d-flex justify-content-end'>
            <CustomButton onClick={this.handleSubmit}>
              {this.state.stepOne ? "Kreiraj proizvod" : "Sledeći korak"}
            </CustomButton>

            {this.state.stepOne ?
                <div className="ml-10">
                    <CustomButton onClick={this.showModal} >
                      Pregled
                    </CustomButton>
                </div>
              : null
              }

          </div>
        </div>
      </div>
    );
  }
}

export default AddProduct;
