import React, { Component } from "react";
import { deletePartner, getAllPartners } from "../../functions/postFunctions";
import { CustomButton } from "../../components/Buttons/Button";

import AddPartner from "../../components/AddPartner/AddPartner";
import EditPartner from "../../components/EditPartner/EditPartner";
import SortableListPartners from "../../components/SortableGrid/SortableListPartners";

class PartneriPage extends Component {
  state = {
    theads: ["Logo", "Link", "Akcije"],
    partners: [],
    showAddCompetition: false,
    showEditCompetition: false,
    competitionForEdit: null
  };

  componentDidMount() {
    this.props.setBreadcrumbs("Partneri");
    getAllPartners().then(res => this.setState({ partners: res.partners }));
  }

  handleDeletePage = id => {
    deletePartner(id, this.props.token).then(res => {
      if (res.success) {
        this.setState({ partners: res.partners });
      }
    });
  };

  showModal = (name, id) => {
    let partner;
    if (id) {
      const { partners } = this.state;
      partner = partners.find(e => e.id === id);
    }
    this.setState({ [name]: true, competitionForEdit: partner });
  };

  hideModal = () => {
    this.setState({
      showAddCompetition: false,
      showEditCompetition: false,
      competitionForEdit: null
    });
  };

  addCompetitionToList = partner => {
    this.setState(prevState => ({
      partners: [...prevState.partners, partner],
      showAddCompetition: false
    }));
  };

  refreshList = () => {
    getAllPartners().then(res =>
      this.setState({
        partners: res.partners,
        showEditCompetition: false,
        competitionsForEdit: null
      })
    );
  };

  render() {
    const { theads, partners, competitionForEdit } = this.state;
    let server;
    if (process.env.NODE_ENV === "development") {
      server = "http://127.0.0.8/";
    } else {
      server = "https://admin.fk-buducnost.me/";
    }
    return (
      <div>
        <div className="pageTop mb-40">
          <CustomButton onClick={() => this.showModal("showAddCompetition")}>
            Dodaj partnera
          </CustomButton>
          <AddPartner
            visible={this.state.showAddCompetition}
            hideModal={this.hideModal}
            token={this.props.token}
            addCompetitionToList={this.addCompetitionToList}
          />
          <EditPartner
            visible={this.state.showEditCompetition}
            hideModal={this.hideModal}
            token={this.props.token}
            refreshList={this.refreshList}
            competition={competitionForEdit}
          />
        </div>
        <SortableListPartners
          items={partners}
          showModal={this.showModal}
          deleteMenuItem={this.handleDeletePage}
          token={this.props.token}
        />
        {/*<Table theads={theads}>*/}
        {/*  {partners.map((e, index) => {*/}
        {/*    return (*/}
        {/*      <tr key={index}>*/}
        {/*        <td className="td px-10 lbg">*/}
        {/*          <div className="wrapper d-flex justify-content-center align-items-center">*/}
        {/*            {e.image !== "" ? (*/}
        {/*              <img*/}
        {/*                src={`${server}/${e.image}`}*/}
        {/*                alt={""}*/}
        {/*                style={{ maxHeight: "50px" }}*/}
        {/*              />*/}
        {/*            ) : (*/}
        {/*              <span className="f-s-24 text-color-white">X</span>*/}
        {/*            )}*/}
        {/*          </div>*/}
        {/*        </td>*/}
        {/*        <td className="td px-10">*/}
        {/*          <span className="f-s-16">{e.link}</span>*/}
        {/*        </td>*/}
        {/*        <td className="td px-10" style={{ minWidth: "220px" }}>*/}
        {/*          <CustomButton*/}
        {/*            onClick={() => this.showModal("showEditCompetition", e.id)}*/}
        {/*            className="mr-20"*/}
        {/*          >*/}
        {/*            Izmjeni*/}
        {/*          </CustomButton>*/}
        {/*          <CustomButton*/}
        {/*            onClick={() => this.handleDeletePage(e.id)}*/}
        {/*            className="red"*/}
        {/*          >*/}
        {/*            Izbriši*/}
        {/*          </CustomButton>*/}
        {/*        </td>*/}
        {/*      </tr>*/}
        {/*    );*/}
        {/*  })}*/}
        {/*</Table>*/}
      </div>
    );
  }
}

export default PartneriPage;
