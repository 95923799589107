import React from "react";
import { Link } from "react-router-dom";

export const LinkButton = ({ className, href, blank, children, onClick }) => {
  return (
    <Link
      onClick={onClick}
      className={`${className} btn btn-primary f-s-16 text-color-white transition-1 px-20 py-5 br-r-5`}
      to={href}
      target={blank && "_blank"}>
      {children}
    </Link>
  );
};

export const CustomButton = ({ className, onClick, children, type }) => {
  return (
    <button
      className={`${className} btn btn-primary f-s-16 text-color-white transition-1 px-20 py-5 br-r-5`}
      onClick={onClick}
      type={type || ""}>
      {children}
    </button>
  );
};
