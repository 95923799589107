import React, { Component } from "react";
import { CustomInput, CustomTextArea } from "../../../components/Form/Inputs/Inputs";
import { createSlug } from "../../../functions/functions";
import TextEditor from "../../../components/TextEditor/TextEditor";
import { CustomButton } from "../../../components/Buttons/Button";
import { addPageValidiation } from "../../../functions/validateFunctions";
import { addPage, getAllCategoriesPage } from "../../../functions/postFunctions";
import SortableGrid from "../../../components/SortableGrid/SortableGrid";
import DatePicker from "react-date-picker";
import SearchableDropdown from "../../../components/Form/Dropdown/SearchableDropdown";

class AddPage extends Component {
  state = {
    pages: [
      {
        language_id: 1,
        title: "",
        description: "",
        content: "",
        slug: "",
        date: new Date(),
        content_right: "",
      },
    ],
    errors: [],
    showErrorPopup: false,
    showWarningPopup: false,
    images: null,
    active: "",
    listOfImages: [],
    stepOne: false,
    edit: false,
    completed: 0,
    showUploadPopup: false,
    uploadComplete: false,
    rightSide: false,
    categoryList: [],
    category_id: 0,
  };

  componentDidMount() {
    this.props.setBreadcrumbs("Dodaj stranicu");
    getAllCategoriesPage().then((res) => {
      console.log(res);
      if (res.success) {
        const categoryList = res.categories.map((e) => ({
          value: e.id,
          label: e.name,
        }));
        this.setState({ categoryList });
      }
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.errors != this.state.errors && this.state.errors.length > 0) {
      this.setState({ showErrorPopup: true });
    }
    if (prevState.stepOne !== this.state.stepOne) {
      const item = document.querySelector(".gallery");
      this.navigationMove(item, "gallery");
    }
  }

  handleInput = (name, value, index) => {
    if (name === "category_id") {
      this.setState({ category_id: value });
    } else if (name === "title") {
      this.setState(({ pages }) => ({
        pages: [
          ...pages.slice(0, index),
          {
            ...pages[index],
            title: value,
            slug: createSlug(value),
          },
          ...pages.slice(index + 1),
        ],
      }));
    } else if (name === "content" || name === "content_right") {
      this.setState(({ pages }) => ({
        pages: [
          ...pages.slice(0, index),
          {
            ...pages[index],
            [name]: value.srcElement.innerText,
          },
          ...pages.slice(index + 1),
        ],
      }));
    } else {
      this.setState(({ pages }) => ({
        pages: [
          ...pages.slice(0, index),
          {
            ...pages[index],
            [name]: value,
          },
          ...pages.slice(index + 1),
        ],
      }));
    }
  };

  handleFileInput = (event) => {
    this.setState({ images: [...event] });
  };

  removePopup = () => {
    this.setState({ showErrorPopup: false, showWarningPopup: false });
  };

  handleSubmit = (event) => {
    const { images, stepOne } = this.state;
    event.preventDefault();
    const errors = addPageValidiation(this.state);
    if (errors.length === 0) {
      if (!stepOne) {
        this.setState({ stepOne: true, active: "gallery" });
      } else {
        if (!images || images.length === 0) {
          this.setState({ showWarningPopup: true });
        } else {
          this.createPage();
        }
      }
    } else {
      this.setState({ errors });
    }
  };

  createPage = () => {
    const { images, category_id } = this.state;
    const data = new FormData();

    if (images) {
      images.forEach((e) => {
        data.append("images[]", e);
      });
    }
    const pages = this.state.pages.map((e) => {
      if (!this.state.rightSide) {
        e.content_right = null;
      }
      e.category_id = category_id;
      return e;
    });
    data.append("pages", JSON.stringify(pages));
    this.setState({ showUploadPopup: true });
    const config = {
      onUploadProgress: (progressEvent) => {
        const completed = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        this.setState({
          completed,
          uploadComplete: completed === 100,
        });
      },
    };
    addPage(data, this.props.token, config).then((res) => {
      if (res.success) {
        this.props.history.push("/");
      } else {
        this.setState({ showErrorPopup: true });
      }
    });
  };

  underlineRef = React.createRef();

  navigationMove = (item, active) => {
    item.parentElement.querySelectorAll(".link").forEach((e) => {
      e.classList.remove("text-color-primary");
      e.classList.remove("text-color-font-l");
    });
    item.classList.add("text-color-primary");
    this.underlineRef.current.style.transform = `translateX(${item.offsetLeft}px)`;
    this.setState({ active });
  };

  toggleRightSideContent = () => {
    this.setState(({ rightSide }) => ({
      rightSide: !rightSide,
    }));
  };

  render() {
    const { categoryList } = this.state;
    return (
      <div className='wrapper'>
        <div className={`errorPopup ${this.state.showWarningPopup ? "popupShown" : ""}`}>
          <div className='content py-20 px-10 d-flex justify-content-between flex-column'>
            <h3 className='f-s-22 f-w-4 uppercase text-center text-color-red'>Upozorenje</h3>
            <h5 className='f-s-16 f-w-4 uppercase text-center'>
              Nijedna fotografija nije selektovana. Ukoliko nastavite default-na cover fotografija
              će biti korištena
            </h5>
            <div className='button text-center mt-30'>
              <CustomButton className='mr-10 red' onClick={this.createPage}>
                Nastavi
              </CustomButton>
              <CustomButton onClick={this.removePopup}>Zatvori</CustomButton>
            </div>
          </div>
        </div>
        <div className={`errorPopup ${this.state.showUploadPopup ? "popupShown" : ""}`}>
          <div className='content py-20 px-10 d-flex justify-content-center flex-column'>
            <h3 className='f-s-22 f-w-4 uppercase text-center text-color-primary'>
              {!this.state.uploadComplete ? "Upload u toku" : "Optimizacija u toku"}
            </h3>
            {!this.state.uploadComplete ? (
              <div className='uploadBar d-flex align-items-center p-r'>
                <div className='barWrapper'>
                  <span
                    className='bar d-flex align-items-center justify-content-center'
                    style={{ width: `${this.state.completed}%` }}
                  />
                </div>
                <span className='outerProgress f-s-16 center-a-b text-color-primary'>
                  {this.state.completed}%
                </span>
              </div>
            ) : (
              <div className='optimization loader d-flex justify-content-center align-items-center'>
                <div className='fancy-spinner'>
                  <div className='ring' />
                  <div className='ring' />
                  <div className='dot' />
                </div>
              </div>
            )}
          </div>
        </div>
        {this.state.stepOne && (
          <div className='innerNavigation pb-10 mb-40'>
            <span
              className='f-s-16 mr-20 uppercase text-color-primary link'
              onClick={(e) => this.navigationMove(e.currentTarget, "content")}>
              Sadržaj
            </span>
            <span
              className='f-s-16 uppercase text-color-font-l link gallery'
              onClick={(e) => this.navigationMove(e.currentTarget, "gallery")}>
              Galerija
            </span>
            <span className='indicator' ref={this.underlineRef} />
          </div>
        )}
        {!this.state.stepOne || this.state.active === "content" ? (
          <div className='contentWrapper'>
            <div className={` errorPopup ${this.state.showErrorPopup ? "popupShown" : ""}`}>
              <div className='content py-20 px-10 d-flex justify-content-between flex-column'>
                <h3 className='f-s-18 f-w-4 uppercase text-center'>
                  Problemi prilikom kreiranja stranice
                </h3>
                <ul className='mt-30'>
                  {this.state.errors.map((e, index) => {
                    return (
                      <li key={index} className='f-s-16 text-center'>
                        {e}
                      </li>
                    );
                  })}
                </ul>
                <div className='button text-center mt-30'>
                  <CustomButton onClick={this.removePopup}>Zatvori</CustomButton>
                </div>
              </div>
            </div>
            <form onSubmit={(e) => e.preventDefault()}>
              <h2 className='uppercase f-s-20 text-color-primary f-w-4'>Crnogorski</h2>
              <div className='row mt-20'>
                <div className='col-lg-4'>
                  <CustomInput
                    label='Naslov'
                    value={this.state.pages[0].title}
                    handleChange={this.handleInput}
                    name='title'
                    index={0}
                  />
                </div>
                <div className='col-lg-3'>
                  <DatePicker
                    onChange={(e) => this.handleInput("date", e, 0)}
                    value={this.state.pages[0].date}
                    locale={"bs-BS"}
                  />
                </div>
                <div className='col-lg-3'>
                  <CustomInput
                    label='Link'
                    value={this.state.pages[0].slug}
                    handleChange={this.handleInput}
                    name='slug'
                    readOnly
                  />
                </div>
                <div className='col-md-2'>
                  <SearchableDropdown
                    data={categoryList}
                    placeholder='Kategorija'
                    name='category_id'
                    handleChange={this.handleInput}
                  />
                </div>
              </div>
              <div className='row mt-30'>
                <div className='col-12'>
                  <CustomTextArea
                    label='Opis'
                    value={this.state.pages[0].description}
                    handleChange={this.handleInput}
                    name='description'
                    className='textArea'
                    index={0}
                  />
                </div>
              </div>
              <div className='row mt-30'>
                <div className='col-12 text-right'>
                  <CustomButton onClick={this.toggleRightSideContent}>
                    {!this.state.rightSide ? "Dodaj drugu kolonu" : "Ukloni drugu kolonu"}
                  </CustomButton>
                </div>
              </div>
              <div className='row mt-10'>
                <div className={`${!this.state.rightSide ? "col-12" : "col-md-6"}`}>
                  <TextEditor
                    name='content'
                    handleInput={this.handleInput}
                    index={0}
                    value={this.state.pages[0].content}
                  />
                </div>
                {this.state.rightSide && (
                  <div className='col-md-6'>
                    <TextEditor
                      name='content_right'
                      handleInput={this.handleInput}
                      index={0}
                      value={this.state.pages[0].content_right}
                    />
                  </div>
                )}
              </div>
            </form>
          </div>
        ) : (
          <div className='galleryWrapper'>
            <CustomInput
              label='Dodaj fotografije'
              value={null}
              handleChange={this.handleFileInput}
              name='file[]'
              index={0}
              type='file'
              multiple
            />
            {this.state.images ? (
              <div className='selectedImages my-20 py-10 px-15'>
                <h3 className='f-s-16 text-color-primary f-w-4'>Fotografije odabrane za upload:</h3>
                <ul className='mt-20'>
                  {this.state.images.map((e, index) => {
                    return (
                      <li key={index} className='f-s-12'>
                        {index + 1}. {e.name}
                      </li>
                    );
                  })}
                </ul>
              </div>
            ) : (
              ""
            )}
            <SortableGrid images={this.state.listOfImages} />
          </div>
        )}
        <div className='row mt-30'>
          <div className='col-12 d-flex justify-content-end'>
            <CustomButton onClick={this.handleSubmit}>
              {this.state.stepOne ? "Kreiraj stranicu" : "Sledeći korak"}
            </CustomButton>
          </div>
        </div>
      </div>
    );
  }
}

export default AddPage;
