import { CustomButton, LinkButton } from "../../components/Buttons/Button";
import React, { Component } from "react";
import {
  addDiscount,
  addFilterCategory,
  createProductFilter,
  deleteDiscount,
  deleteProductFilterCategory,
  editDiscount,
  editProductFilterCategory,
  getAllDiscounts,
  getAllProductFilterCategories,
  getAllTeams,
  updateProductFilter
} from "../../functions/postFunctions";
import { addDiscountValidation } from "../../functions/validateFunctions";

import { CustomInput } from "../../components/Form/Inputs/Inputs";
import DatePicker from "react-date-picker";
import Table from "../../components/Table/Table";

class FiltersPage extends Component {
  state = {
    videos: [],
    filterCategoryName: "",
    discount: "",
    date_from: new Date(),
    date_to: new Date(),
    showAddTeam: false,
    showEditTeam: false,
    teamForEdit: null,
    errors: [],
    showErrorPopup: false,
    responseErrors: [],
    addingVideo: false,
    theads: ["Naziv", "Akcije"],
    categories: [],
    filters: []
  };

  componentDidMount() {
    this.props.setBreadcrumbs("Filteri");
    getAllProductFilterCategories().then(res => {
      if (res.success) {
        this.setState({ categories: res.categories });
      }
    });
  }

  handleDeletePage = id => {
    deleteDiscount(id, this.props.token).then(res => {
      if (res.success) {
        this.setState({ discounts: res.discounts });
      }
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.categories !== this.state.categories) {
      let filters = [];
      this.state.categories.forEach(_ => {
        filters.push({ name: "", value: "" });
      });
      this.setState({ filters });
    }
  }

  showModal = (name, id) => {
    let team;
    if (id) {
      const { teams } = this.state;
      team = teams.find(e => e.id === id);
    }
    this.setState({ [name]: true, teamForEdit: team });
  };

  hideModal = () => {
    this.setState({
      showAddTeam: false,
      showEditTeam: false,
      teamForEdit: null
    });
  };

  addTeamToList = team => {
    this.setState(prevState => ({
      teams: [...prevState.teams, team],
      showAddTeam: false
    }));
  };

  refreshList = () => {
    getAllTeams().then(res =>
      this.setState({
        teams: res.teams,
        showEditTeam: false,
        teamForEdit: null
      })
    );
  };

  handleInput = (name, value) => {
    this.setState({
      [name]: value
    });
  };
  handleFilterInput = (name, value, index) => {
    this.setState(({ filters }) => ({
      filters: [
        ...filters.slice(0, index),
        {
          ...filters[index],
          [name]: value
        },
        ...filters.slice(index + 1)
      ]
    }));
  };

  handleInputMultiple = (name, value, index) => {
    this.setState(({ categories }) => ({
      categories: [
        ...categories.slice(0, index),
        {
          ...categories[index],
          [name]: value
        },
        ...categories.slice(index + 1)
      ]
    }));
  };

  removePopup = () => {
    this.setState({ showErrorPopup: false });
  };

  handleAddFilterCategory = () => {
    addFilterCategory(this.state.filterCategoryName, this.props.token).then(
      res => {
        if (res.success) {
          this.setState({ categories: [] }, () => {
            this.setState({ categories: res.categories });
          });
        }
      }
    );
  };

  handleUpdateFilterCategory = id => {
    const category = this.state.categories.find(e => e.id === id);
    const { name } = category;

    editProductFilterCategory(name, id, this.props.token).then(res => {
      if (res.success) {
        this.setState({ categories: [] }, () => {
          this.setState({ categories: res.categories });
        });
      }
    });
  };

  handleDeleteProductFilterCategory = id => {
    deleteProductFilterCategory(id, this.props.token).then(res => {
      if (res.success) {
        this.setState({ categories: [] }, () => {
          this.setState({ categories: res.categories });
        });
      }
    });
  };
  handleAddFilter = (index, category_id) => {
    const filter = this.state.filters[index];
    createProductFilter(
      filter.name,
      filter.value,
      category_id,
      this.props.token
    ).then(res => {
      if (res.success) {
        this.setState({ categories: [] }, () => {
          this.setState({ categories: res.categories });
        });
      }
    });
  };

  render() {
    const { theads, categories } = this.state;
    let server;
    if (process.env.NODE_ENV === "development") {
      server = "http://127.0.0.8/";
    } else {
      server = "https://admin.fk-buducnost.me/";
    }

    return (
      <div>
        <div
          className={` errorPopup ${
            this.state.showErrorPopup ? "popupShown" : ""
          }`}
        >
          <div className="content py-20 px-10 d-flex justify-content-between flex-column">
            <h3 className="f-s-18 f-w-4 uppercase text-center">
              Problemi prilikom kreiranja popusta
            </h3>
            {this.state.errors.length > 0 ? (
              <ul className="mt-30">
                {this.state.errors.map((e, index) => {
                  return (
                    <li key={index} className="f-s-16 text-center">
                      {e}
                    </li>
                  );
                })}
              </ul>
            ) : (
              <ul className="mt-30">
                {this.state.responseErrors.map(e => {
                  return e.map((el, index) => (
                    <li key={index} className="f-s-16 text-center">
                      {e}
                    </li>
                  ));
                })}
              </ul>
            )}
            <div className="button text-center mt-30">
              <CustomButton onClick={this.removePopup}>Zatvori</CustomButton>
            </div>
          </div>
        </div>
        <div className="pageTop mb-40">
          {this.state.addingVideo ? (
            <div className="content py-20 px-10 d-flex justify-content-center flex-column">
              <h3 className="f-s-22 f-w-4 uppercase text-center text-color-primary">
                Dodavanje popusta
              </h3>
              <div className="optimization loader d-flex justify-content-center align-items-center">
                <div className="fancy-spinner">
                  <div className="ring" />
                  <div className="ring" />
                  <div className="dot" />
                </div>
              </div>
            </div>
          ) : (
            <div className="row">
              <h2 className="f-s-18 f-w-4 text-color-primary mb-20">
                Dodaj filter kategoriju
              </h2>
              <div className="row" style={{ width: "100%" }}>
                <div className="col-md-2">
                  <p className="f-s-14 text-color-primary">Naziv</p>
                </div>
              </div>
              <div className="row" style={{ width: "100%" }}>
                <div className="col-md-2">
                  <CustomInput
                    value={this.state.filterCategoryName}
                    handleChange={this.handleInput}
                    name="filterCategoryName"
                    realTime
                  />
                </div>
                <div className="col-md-3">
                  <CustomButton onClick={this.handleAddFilterCategory}>
                    Dodaj filter kategoriju
                  </CustomButton>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="row">
          <Table theads={theads}>
            {categories.map((e, index) => {
              return (
                <>
                  <tr key={index}>
                    <td className="td px-10">
                      <CustomInput
                        value={e.name}
                        handleChange={this.handleInputMultiple}
                        name="name"
                        index={index}
                      />
                    </td>
                    <td className="td px-10" style={{ minWidth: "220px" }}>
                      <CustomButton
                        className="mr-20"
                        onClick={() => this.handleUpdateFilterCategory(e.id)}
                      >
                        Izmjeni
                      </CustomButton>
                      <CustomButton
                        onClick={() =>
                          this.handleDeleteProductFilterCategory(e.id)
                        }
                        className="red"
                      >
                        Izbriši
                      </CustomButton>
                    </td>
                  </tr>
                  <div className="wrapper pl-20">
                    {e.filters.length > 0 && (
                      <Table
                        className="subList"
                        theads={["Naziv filtera", "Vrijednost filtera"]}
                      >
                        {e.filters.map(el => (
                          <tr>
                            <td>
                              <CustomInput
                                value={el.name}
                                handleChange={this.handleInputMultiple}
                                name="name"
                                index={index}
                              />
                            </td>
                            <td>
                              <CustomInput
                                value={el.value}
                                handleChange={this.handleInputMultiple}
                                name="name"
                                index={index}
                              />
                            </td>
                          </tr>
                        ))}
                      </Table>
                    )}
                  </div>
                  <div className="wrapper mt-20 pl-20">
                    <div className="row pl-20">
                      <div className="row" style={{ width: "100%" }}>
                        <div className="col-md-3">
                          <p className="f-s-14 text-color-primary">Naziv</p>
                        </div>
                        <div className="col-md-3">
                          <p className="f-s-14 text-color-primary">
                            Vrijednost
                          </p>
                        </div>
                      </div>
                      {this.state.filters.length > 0 && (
                        <div className="row" style={{ width: "100%" }}>
                          <div className="col-md-3">
                            <CustomInput
                              value={this.state.filters[index].name}
                              handleChange={this.handleFilterInput}
                              name="name"
                              realTime
                              index={index}
                            />
                          </div>
                          <div className="col-md-3">
                            <CustomInput
                              value={this.state.filters[index].value}
                              handleChange={this.handleFilterInput}
                              name="value"
                              realTime
                              index={index}
                            />
                          </div>
                          <div className="col-md-3">
                            <CustomButton
                              onClick={() => this.handleAddFilter(index, e.id)}
                            >
                              Dodaj filter
                            </CustomButton>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </>
              );
            })}
          </Table>
        </div>
      </div>
    );
  }
}

export default FiltersPage;
