import { CustomButton, LinkButton } from "../../../components/Buttons/Button";
import { CustomInput } from "../../../components/Form/Inputs/Inputs";
import React, { Component } from "react";
import {
  addImagesProduct,
  getAllDiscounts,
  getAllProductCategory,
  getAllProductFilterCategories,
  getProduct,
  updateProduct,
} from "../../../functions/postFunctions";
import { addProductValidation } from "../../../functions/validateFunctions";

import SearchableDropdown from "../../../components/Form/Dropdown/SearchableDropdown";
import SearchableDropdownMultiple from "../../../components/Form/Dropdown/SearchableDropdownMultiple";
import SortableGrid from "../../../components/SortableGrid/SortableGrid";
import TextEditor from "../../../components/TextEditor/TextEditor";

class EditProduct extends Component {
  state = {
    id: 0,
    language_id: 1,
    name: "",
    description: "",
    content: "",
    product_code: "",
    date: new Date(),
    content_right: "",
    errors: [],
    showErrorPopup: false,
    showWarningPopup: false,
    images: null,
    active: "content",
    listOfImages: [],
    stepOne: false,
    edit: true,
    completed: 0,
    showUploadPopup: false,
    uploadComplete: false,
    rightSide: false,
    categoryList: [],
    category_id: 0,
    unit: "",
    price: "",
    uploadedImages: [],
    amount: "",
    sizes: [],
    allSizes: [],
    discounts: [],
    discount: { value: 0, label: "Bez popusta" },
    specifications: [],
    filters: [],
    filterOptions: [],
  };

  componentDidMount() {
    this.props.setBreadcrumbs("Izmjeni proizvod");

    let categories = [];

    const handleRecursion = (arr) => {
      arr.forEach((e) => {
        categories.push({ value: e.id, label: e.name });
        if (e.children.length > 0) {
          handleRecursion(e.children);
        }
      });
    };

    getAllProductCategory().then((res) => {
      // if (res.success) {
      //   handleRecursion(res.categories);
      //   console.log(categories);
      //   const currentCategory = categories.find((e) => e.value === 0);
      //   let options = [];
      //   let option = null;

      //   categories.forEach((e) => {
      //     if (e.level === 0) {
      //       if (option) {
      //         options.push(option);
      //       }
      //       option = null;
      //       option = {
      //         label: e.name,
      //         options: [{ value: e.id, label: e.name }],
      //       };
      //     } else {
      //       option.options.push({ value: e.id, label: e.name });
      //     }
      //   });

      //   const categoryList = [...options];
      //   console.log(categoryList);
      //   this.setState({ categoryList, currentCategory });
      // }
      if (res.success) {
        handleRecursion(res.categories);
        const currentCategory = categories.find((e) => e.value === 0);
        this.setState({ categoryList: categories, currentCategory });
      }
    });

    getProduct(this.props.match.params.id).then((res) => {
      if (res.success) {
        console.log(res)
        this.setState(
          {
            ...res.product,
            discount: { value: res.product.discount_id, label: "" },
            uploadedImages: res.images,
            specifications: res.specifications,
            sizes: res.sizes,
            filters: res.filters.map((e) => ({ id: e.filter_id })),
          },
          () => {
            getAllDiscounts().then((res) => {
              if (res.success) {
                const discounts = res.discounts.map((e) => ({
                  value: e.id,
                  label: `${e.name} (${e.discount}%)`,
                }));
                const discount = discounts.find((e) => e.value === this.state.discount.value);
                discounts.unshift({ value: 0, label: "Bez popusta" });
                this.setState({
                  discounts,
                  discount: discount || { value: 0, label: "Bez popusta" },
                });
              }
            });
          }
        );
      } else {
        this.props.history.push(`/proizvodi/${localStorage.getItem("page")}`);
      }
    });

    getAllProductFilterCategories().then((res) => {
      if (res.success) {
        const filterOptions = [];
        res.categories.forEach((e) => {
          filterOptions.push(
            ...e.filters.map((el) => ({ label: `${e.name} - ${el.name}`, value: el.id }))
          );
        });
        this.setState({ filterOptions });
      }
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.errors != this.state.errors && this.state.errors.length > 0) {
      this.setState({ showErrorPopup: true });
    }
    if (prevState.stepOne !== this.state.stepOne) {
      const item = document.querySelector(".gallery");
      this.navigationMove(item, "gallery");
    }
  }

  handleInput = (name, value, index, i) => {
    if (name === "size" || name === "amount") {
      this.setState(({ sizes }) => ({
        sizes: [
          ...sizes.slice(0, index),
          {
            ...sizes[index],
            [name]: value,
          },
          ...sizes.slice(index + 1),
        ],
      }));
    } else if (name === "filter") {
      const ind = typeof index === "number" ? index : i;
      this.setState(({ filters }) => ({
        filters: [
          ...filters.slice(0, ind),
          {
            ...filters[ind],
            id: typeof value === "number" ? value : value.value,
          },
          ...filters.slice(ind + 1),
        ],
      }));
    } else if (name === "value" || name === "label") {
      this.setState(({ specifications }) => ({
        specifications: [
          ...specifications.slice(0, index),
          {
            ...specifications[index],
            [name]: value,
          },
          ...specifications.slice(index + 1),
        ],
      }));
    } else if (name === "description") {
      console.log(value.src);
      this.setState({ description: value.srcElement.innerHTML });
    } else {
      this.setState({ [name]: value });
    }
  };

  handleFileInput = (event) => {
    this.setState({ images: [...event] });
  };

  removePopup = () => {
    this.setState({ showErrorPopup: false, showWarningPopup: false });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const { active } = this.state;
    const errors = addProductValidation(this.state, true);
    if (errors.length === 0) {
      if (active === "content") {
        this.handleUpdateProduct();
      } else {
        this.handleAddImages();
      }
    } else {
      this.setState({ errors });
    }
  };

  handleUpdateProduct = () => {
    const {
      id,
      category_id,
      name,
      amount,
      unit,
      price,
      product_code,
      description,
      sizes,
      discount,
      specifications,
      filters,
    } = this.state;

    const data = {
      product_id: id,
      category_id: category_id.value ? category_id.value : category_id,
      name,
      amount,
      unit,
      price,
      product_code,
      description,
      sizes: JSON.stringify(sizes),
      discount_id: discount.value,
      specifications: JSON.stringify(specifications),
      filters: JSON.stringify(filters.filter((e) => e.id !== 0)),
    };
    updateProduct(data, this.props.token).then((res) => {
      if (res.success) {
        this.props.history.push(`/proizvodi/${localStorage.getItem("page")}`);
      } else {
        this.setState({ showErrorPopup: true });
      }
    });
  };

  handleAddImages = () => {
    const { images, id } = this.state;
    const data = new FormData();
    if (images) {
      images.forEach((e) => {
        data.append("images[]", e);
      });
    }
    data.append("product_id", id);
    const config = {
      onUploadProgress: (progressEvent) => {
        const completed = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        this.setState({
          completed,
          uploadComplete: completed === 100,
        });
      },
    };
    addImagesProduct(data, this.props.token, config).then((res) => {
      if (res.success) {
        this.props.history.push(`/proizvodi/${localStorage.getItem("page")}`);
      } else {
        this.setState({ showErrorPopup: true });
      }
    });
  };

  removeImage = (id) => {
    const { uploadedImages } = this.state;
    const newList = uploadedImages.filter((e) => e.id !== id);
    this.setState({ uploadedImages: newList });
  };

  underlineRef = React.createRef();

  navigationMove = (item, active) => {
    item.parentElement.querySelectorAll(".link").forEach((e) => {
      e.classList.remove("text-color-primary");
      e.classList.remove("text-color-font-l");
    });
    item.classList.add("text-color-primary");
    this.underlineRef.current.style.transform = `translateX(${item.offsetLeft}px)`;
    this.setState({ active });
  };

  toggleRightSideContent = () => {
    this.setState(({ rightSide }) => ({
      rightSide: !rightSide,
    }));
  };

  handleRemoveSize = (index) => {
    const sizes = this.state.sizes.filter((_, i) => i !== index);
    this.setState({ sizes: [] }, () => this.setState({ sizes }));
  };

  handleRemoveFilter = (index) => {
    const filters = this.state.filters.filter((_, i) => i !== index);
    this.setState({ filters: [] }, () => this.setState({ filters }));
  };

  addSpecification = () => {
    const specification = { label: "", value: "", product_id: this.state.id };

    this.setState(({ specifications }) => ({
      specifications: [...specifications, specification],
    }));
  };

  deleteSpecification = (index) => {
    const specifications = this.state.specifications.filter((_, i) => i !== index);
    this.setState({ specifications: [] }, () => this.setState({ specifications }));
  };

  handleAddSize = () => {
    this.setState(({ sizes }) => ({
      sizes: [...sizes, { amount: "", size: "" }],
    }));
  };

  handleAddFilter = () => {
    this.setState(({ filters }) => ({
      filters: [...filters, { id: 0 }],
    }));
  };

  render() {
    const { categoryList, allSizes, discounts } = this.state;

    const units = [
      { value: 0, label: "Komad" },
      { value: 1, label: "Kutija" },
    ];

    let values = [...categoryList];
    // categoryList.forEach((e) => {
    //   e.options.map((el) => values.push(el));
    // });
    return (
      <div className='wrapper'>
        <div className={`errorPopup ${this.state.showWarningPopup ? "popupShown" : ""}`}>
          <div className='content py-20 px-10 d-flex justify-content-between flex-column'>
            <h3 className='f-s-22 f-w-4 uppercase text-center text-color-red'>Upozorenje</h3>
            <h5 className='f-s-16 f-w-4 uppercase text-center'>
              Nijedna fotografija nije selektovana. Ukoliko nastavite default-na cover fotografija
              će biti korištena
            </h5>
            <div className='button text-center mt-30'>
              <CustomButton className='mr-10 red' onClick={this.createPage}>
                Nastavi
              </CustomButton>
              <CustomButton onClick={this.removePopup}>Zatvori</CustomButton>
            </div>
          </div>
        </div>
        <div className={`errorPopup ${this.state.showUploadPopup ? "popupShown" : ""}`}>
          <div className='content py-20 px-10 d-flex justify-content-center flex-column'>
            <h3 className='f-s-22 f-w-4 uppercase text-center text-color-primary'>
              {!this.state.uploadComplete ? "Upload u toku" : "Optimizacija u toku"}
            </h3>
            {!this.state.uploadComplete ? (
              <div className='uploadBar d-flex align-items-center p-r'>
                <div className='barWrapper'>
                  <span
                    className='bar d-flex align-items-center justify-content-center'
                    style={{ width: `${this.state.completed}%` }}
                  />
                </div>
                <span className='outerProgress f-s-16 center-a-b text-color-primary'>
                  {this.state.completed}%
                </span>
              </div>
            ) : (
              <div className='optimization loader d-flex justify-content-center align-items-center'>
                <div className='fancy-spinner'>
                  <div className='ring' />
                  <div className='ring' />
                  <div className='dot' />
                </div>
              </div>
            )}
          </div>
        </div>
        {this.state.edit ? (
          <div className='innerNavigation pb-10 mb-40'>
            <span
              className='f-s-16 mr-20 uppercase text-color-primary link'
              onClick={(e) => this.navigationMove(e.currentTarget, "content")}>
              Sadržaj
            </span>
            <span
              className='f-s-16 uppercase text-color-font-l link gallery'
              onClick={(e) => this.navigationMove(e.currentTarget, "gallery")}>
              Galerija
            </span>
            <span className='indicator' ref={this.underlineRef} />
          </div>
        ) : (
          ""
        )}
        {this.state.active === "content" ? (
          <div className='contentWrapper'>
            <div className={` errorPopup ${this.state.showErrorPopup ? "popupShown" : ""}`}>
              <div className='content py-20 px-10 d-flex justify-content-between flex-column'>
                <h3 className='f-s-18 f-w-4 uppercase text-center'>
                  Problemi prilikom kreiranja proizvoda
                </h3>
                <ul className='mt-30'>
                  {this.state.errors.map((e, index) => {
                    return (
                      <li key={index} className='f-s-16 text-center'>
                        {e}
                      </li>
                    );
                  })}
                </ul>
                <div className='button text-center mt-30'>
                  <CustomButton onClick={this.removePopup}>Zatvori</CustomButton>
                </div>
              </div>
            </div>
            <form onSubmit={(e) => e.preventDefault()}>
              <h2 className='uppercase f-s-20 text-color-primary f-w-4'>Crnogorski</h2>
              <div className='row mt-20'>
                <div className='col-md-4'>
                  <CustomInput
                    label='Naziv'
                    value={this.state.name}
                    handleChange={this.handleInput}
                    name='name'
                    index={0}
                  />
                </div>
                <div className='col-md-4'>
                  {/* <SearchableDropdownMultiple
                    data={categoryList}
                    placeholder='Kategorija'
                    name='category_id'
                    handleChange={this.handleInput}
                    value={values.find((el) => el.value === this.state.category_id)}
                  /> */}
                  <SearchableDropdown
                    data={categoryList}
                    placeholder='Kategorija'
                    name='category_id'
                    handleChange={this.handleInput}
                    value={categoryList.find((el) => el.value === this.state.category_id)}
                  />
                </div>
                <div className='col-lg-4'>
                  <CustomInput
                    label='Šifra proizvoda'
                    value={this.state.product_code}
                    handleChange={this.handleInput}
                    name='product_code'
                    index={0}
                  />
                </div>
              </div>
              <div className='row mt-30'>
                <div className='col-md-3'>
                  <SearchableDropdown
                    value={units.find((e) => e.value === this.state.unit)}
                    data={units}
                    placeholder='Jedinica mjere'
                    name='unit'
                    handleChange={this.handleInput}
                  />
                </div>
                <div className='col-md-3'>
                  <CustomInput
                    label='Stanje'
                    value={this.state.amount}
                    handleChange={this.handleInput}
                    name='amount'
                    index={0}
                  />
                </div>
                <div className='col-md-3'>
                  <CustomInput
                    label='Cijena'
                    value={this.state.price}
                    handleChange={this.handleInput}
                    name='price'
                    index={0}
                    type='number'
                  />
                </div>
                <div className='col-md-3'>
                  <SearchableDropdown
                    data={discounts}
                    placeholder='Popust'
                    name='discount'
                    handleChange={this.handleInput}
                    value={this.state.discount}
                  />
                </div>
              </div>
              <div className='row mt-30'>
                <div className='col-md-3'>
                  <CustomButton onClick={this.handleAddSize}>Dodaj veličinu</CustomButton>
                </div>
              </div>
              <div className='row pt-15 pb-30 mt-10 borders'>
                {this.state.sizes.map((e, index) => (
                  <div className='col-md-6 mt-15' key={index}>
                    <div className='row'>
                      <div className='col-md-4'>
                        <CustomInput
                          label='Veličina'
                          name='size'
                          handleChange={this.handleInput}
                          index={index}
                          value={e.size}
                        />
                      </div>
                      <div className='col-md-4'>
                        <CustomInput
                          label='Količina'
                          value={e.amount}
                          handleChange={this.handleInput}
                          name='amount'
                          index={index}
                          type='number'
                        />
                      </div>
                      <div className='col-md-3'>
                        <CustomButton className='red' onClick={() => this.handleRemoveSize(index)}>
                          X
                        </CustomButton>
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              <div className='row mt-30'>
                <div className='col-md-3'>
                  <CustomButton onClick={this.handleAddFilter}>Dodaj filter</CustomButton>
                </div>
              </div>
              <div className='row pt-15 pb-30 mt-10 borders'>
                {this.state.filters.map((e, index) => (
                  <div className='col-md-4 mt-15' key={index}>
                    <div className='row'>
                      <div className='col-md-9'>
                        <SearchableDropdownMultiple
                          value={this.state.filterOptions.find((el) => el.value === e.id)}
                          data={this.state.filterOptions}
                          placeholder='Filter'
                          name='filter'
                          handleChange={this.handleInput}
                          index={index}
                        />
                      </div>
                      <div className='col-md-3'>
                        <CustomButton
                          className='red'
                          onClick={() => this.handleRemoveFilter(index)}>
                          X
                        </CustomButton>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className='row mt-30'>
                <div className='col-12'>
                  <TextEditor
                    name='description'
                    handleInput={this.handleInput}
                    index={0}
                    value={this.state.description}
                  />
                </div>
              </div>
              {this.state.specifications.map((e, index) => {
                return (
                  <div className='row mt-30' key={index}>
                    <div className='col-md-5'>
                      <CustomInput
                        label='Naziv specifikacije'
                        value={e.label}
                        handleChange={this.handleInput}
                        name='label'
                        index={index}
                      />
                    </div>
                    <div className='col-md-5'>
                      <CustomInput
                        label='Opis specifikacije'
                        value={e.value}
                        handleChange={this.handleInput}
                        name='value'
                        index={index}
                      />
                    </div>
                    <div className='col-md-2'>
                      <CustomButton
                        onClick={(e) => this.deleteSpecification(index)}
                        className='mr-20 red'>
                        Izbriši
                      </CustomButton>
                    </div>
                  </div>
                );
              })}
              <div className='row mt-30'>
                <div className='col-12'>
                  <CustomButton onClick={this.addSpecification} className='mr-20 green'>
                    Dodaj specifikaciju
                  </CustomButton>
                </div>
              </div>
            </form>
          </div>
        ) : (
          <div className='galleryWrapper'>
            <CustomInput
              label='Dodaj fotografije'
              value={null}
              handleChange={this.handleFileInput}
              name='file[]'
              index={0}
              type='file'
              multiple
            />
            {this.state.images ? (
              <div className='selectedImages my-20 py-10 px-15'>
                <h3 className='f-s-16 text-color-primary f-w-4'>Fotografije odabrane za upload:</h3>
                <ul className='mt-20'>
                  {this.state.images.map((e, index) => {
                    return (
                      <li key={index} className='f-s-12'>
                        {index + 1}. {e.name}
                      </li>
                    );
                  })}
                </ul>
              </div>
            ) : (
              ""
            )}
            <SortableGrid
              images={this.state.uploadedImages}
              edit
              token={this.props.token}
              removeImage={this.removeImage}
              products
            />
          </div>
        )}
        <div className='row mt-30'>
          <div className='col-12 d-flex justify-content-end'>
            <div className='row mt-30'>
              <div className='col-12 d-flex justify-content-end'>
                <CustomButton onClick={(e) => this.handleSubmit(e)} className='mr-20'>
                  {this.state.active === "content" ? "Ažuriraj proizvod" : "Dodaj fotografije"}
                </CustomButton>
                <LinkButton className='red' href='/proizvodi/1'>
                  Odustani od izmjena
                </LinkButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default EditProduct;
