import React, { Component } from "react";
import Pagination from "react-js-pagination";
import { getImages } from "../../functions/postFunctions";

class MediasPage extends Component {
  state = {
    images: [],
    page: 1,
    activePage: 1,
    max: 0,
  };

  componentDidMount() {
    this.props.setBreadcrumbs("Mediji");

    if (parseInt(this.props.match.params.page) < 1) {
      this.props.history.push("1");
    }
    this.setState({ activePage: parseInt(this.props.match.params.page) });
    this.setState({ activePage: parseInt(this.props.match.params.page) }, () => {
      getImages(
        20,
        this.state.activePage === 1 ? 0 : (this.state.activePage - 1) * 20,
        this.props.token
      )
        .then((res) => {
          if (res.success) {
            this.setState({
              images: res.images,
              max: res.total,
              activePage: parseInt(this.props.match.params.page),
            });
          }
        })
        .catch((err) => console.log(err));
    });
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.match.params.page !== this.props.match.params.page) {
      this.setState({ activePage: parseInt(this.props.match.params.page) }, () => {
        getImages(
          20,
          this.state.activePage === 1 ? 0 : (this.state.activePage - 1) * 20,
          this.props.token
        )
          .then((res) => {
            if (res.success) {
              this.setState({
                images: res.images,
                max: res.total,
                activePage: parseInt(this.props.match.params.page),
              });
            }
          })
          .catch((err) => console.log(err));
      });
    }
  }

  handlePageChange = (pageNumber) => {
    this.props.history.push(`${pageNumber}`);
  };

  render() {
    let server;
    if (process.env.NODE_ENV === "development") {
      server = "http://127.0.0.8/";
    } else {
      server = "https://admin.fk-buducnost.me/";
    }

    return (
      <div>
        <h2 className='f-s-18 f-w-4 text-color-primary mb-20'>Pregled svih medija</h2>
        <div className='row'>
          {this.state.images.map((image, idx) => {
            return (
              <div key={image.id} className='col-3'>
                <img style={{ width: "100%" }} src={`/${image.image}`} alt={image.id} />
              </div>
            );
          })}
        </div>

        <div className='wrapper mt-40 d-flex justify-content-center'>
          <Pagination
            hideDisabled
            activePage={this.state.activePage}
            itemsCountPerPage={20}
            totalItemsCount={this.state.max}
            pageRangeDisplayed={5}
            onChange={this.handlePageChange}
          />
        </div>
      </div>
    );
  }
}

export default MediasPage;
