import React, { Component } from "react";
import { CustomInput } from "../Form/Inputs/Inputs";
import { CustomButton } from "../Buttons/Button";
import { addMenuItemValidiation } from "../../functions/validateFunctions";
import { editMenuItem, getAllPages } from "../../functions/postFunctions";
import SearchableDropdown from "../Form/Dropdown/SearchableDropdown";

class EditMenuItem extends Component {
  state = {
    id: 0,
    name: "",
    logo: null,
    showErrorPopup: false,
    errors: [],
    responseErrors: [],
    menus: [],
    linkTypes: [
      { value: 0, label: "Interni link" },
      { value: 1, label: "Eksterni link" },
      // { value: 2, label: "Fajl" },
      { value: 3, label: "Placeholder" },
    ],
    linkType: null,
    link: "",
    external: false,
    pages: [],
    parent_id: null,
    language_id: 1,
    order: 0,
    currentParentMenu: {},
    currentParentID: { value: 0, label: "" },
    currentLinkType: { value: 0, label: "" },
    currentPageSelected: { value: 0, label: "" },
  };

  defaultState = {
    name: "",
    logo: null,
    showErrorPopup: false,
    errors: [],
    responseErrors: [],
    menus: [],
    linkTypes: [
      { value: 0, label: "Interni link" },
      { value: 1, label: "Eksterni link" },
      // { value: 2, label: "Fajl" },
      { value: 3, label: "Placeholder" },
    ],
    linkType: null,
    link: "",
    external: false,
    pages: [],
    parent_id: null,
    language_id: 1,
    order: 0,
  };

  componentDidMount() {
    getAllPages(1, 500, 0, 1).then((res) => {
      if (res.success) {
        this.setState({
          pages: [
            { value: "/", label: "Naslovna" },
            ...res.pages.map((e) => ({ value: e.slug, label: e.title })),
          ],
        });
        console.log(res);
      }
    });
  }

  handleInput = (name, value) => {
    console.log(name, value.value);
    this.setState({ [name]: value });
  };

  handleFileInput = (event) => {
    this.setState({ logo: [...event] });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const {
      currentParentID,
      name,
      language_id,
      external,
      id,
      currentPageSelected,
      currentLinkType,
      link,
    } = this.state;

    const orderList = this.state.menus.filter((e) => e.parent_id === currentParentID.value);
    const parentLevel = this.state.menus.find((e) => e.id === currentParentID.value);
    let order = 0;
    let level = 0;
    if (parentLevel) {
      level = parentLevel.level + 1;
    }
    if (orderList.length > 0) {
      order =
        Math.max.apply(
          Math,
          orderList.map((e) => e.order)
        ) + 1;
    }
    let updated = "";

    if (currentLinkType.value !== 3) {
      if (currentLinkType === 0) {
        updated = currentPageSelected.value;
      } else {
        updated = link;
      }
    } else {
      updated = null;
    }
    const data = {
      id,
      name,
      link: currentPageSelected.value,
      external,
      parent_id: currentParentID.value,
      order,
      level,
      language_id,
      placeholder: currentLinkType.value === 3,
      menu_id: parseInt(this.props.id),
    };
    const errors = addMenuItemValidiation(this.state, true);
    if (errors.length === 0) {
      this.props.addToList([]);
      editMenuItem(data, this.props.token).then((res) => {
        if (res.success) {
          this.setState({
            currentParentID: { value: 0, label: "" },
            currentLinkType: { value: 0, label: "" },
            currentPageSelected: { value: 0, label: "" },
            name: "",
            id: 0,
          });
          this.props.addToList(res.items);
        } else {
          let responseErrors = [];
          Object.keys(res.errors).forEach((key, index) => {
            responseErrors.push(res.errors[key]);
          });
          this.props.addToList(this.props.menus);
          this.setState({ responseErrors });
        }
      });
    } else {
      this.setState({ errors });
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.errors !== this.state.errors && this.state.errors.length > 0) {
      this.setState({ showErrorPopup: true });
    }
    if (
      prevState.responseErrors !== this.state.responseErrors &&
      this.state.responseErrors.length > 0
    ) {
      this.setState({ showErrorPopup: true });
    }
    if (prevProps.menus !== this.props.menus) {
      this.setState({ menus: this.findAllMenus(this.props.menus) });
    }
    if (prevState.currentLinkType !== this.state.currentLinkType) {
      const { currentLinkType } = this.state;
      if (currentLinkType.value === 1) {
        this.setState({ external: true });
      } else {
        this.setState({ external: false });
      }
    }
    if (prevProps.itemForEdit !== this.props.itemForEdit && this.props.itemForEdit) {
      const menuList = [
        { value: 0, label: this.props.menuName },
        ...this.state.menus.map((e) => ({ value: e.id, label: e.name })),
      ];
      const currentParentID = menuList.find((e) => e.value === this.props.itemForEdit.parent_id);
      const currentLinkType = this.state.linkTypes.find(
        (e) => e.value === this.getLinkType(this.props.itemForEdit)
      );
      const name = this.props.itemForEdit.name;
      const id = this.props.itemForEdit.id;
      const currentPageSelected = this.state.pages.find(
        (e) => e.value === this.props.itemForEdit.link
      );
      const link = this.props.itemForEdit.link;

      this.setState({
        currentParentID,
        currentLinkType,
        name,
        id,
        link,
        currentPageSelected:
          currentPageSelected !== undefined
            ? currentPageSelected
            : { value: "/", label: "Naslovna" },
      });
    }
    if (prevProps.itemForEdit !== this.props.itemForEdit && !this.props.itemForEdit) {
      this.setState({ name: "" });
    }
  }

  getLinkType = (el) => {
    if (el.link && el.external === 0) {
      return 0;
    }
    if (el.link && el.external === 1) {
      return 1;
    }
    if (el.placeholder === 1) {
      return 3;
    }
  };

  removePopup = () => {
    this.setState({ showErrorPopup: false, errors: [], responseErrors: [] });
  };

  findAllMenus = (array) => {
    let menus = [];
    const handleRecursion = (arr) => {
      arr.forEach((e) => {
        menus.push(e);
        if (e.children.length > 0) {
          handleRecursion(e.children);
        }
      });
    };
    handleRecursion(array);
    return menus;
  };

  renderLink = (linkType) => {
    let item;
    switch (linkType) {
      case 0:
        item = (
          <>
            <label
              htmlFor='linkType'
              className='f-s-14 text-color-font-l'
              style={{
                color: "#7ea5cb",
                marginBottom: "5px",
                display: "block",
              }}>
              Izaberite stranicu
            </label>
            <SearchableDropdown
              data={this.state.pages}
              placeholder='Izaberite stranicu'
              name='currentPageSelected'
              handleChange={this.handleInput}
              value={this.state.currentPageSelected}
            />
          </>
        );
        break;
      case 1:
        item = (
          <>
            <label
              htmlFor='linkType'
              className='f-s-14 text-color-font-l'
              style={{
                color: "#7ea5cb",
                marginBottom: "5px",
                display: "block",
              }}>
              Link
            </label>
            <CustomInput
              value={this.state.link}
              handleChange={this.handleInput}
              name='link'
              index={0}
              counter={false}
            />
          </>
        );
        break;
      case 2:
        item = 2;
        break;
      case 3:
        item = "";
        break;
      default:
        item = "";
        break;
    }
    return item;
  };

  render() {
    const { name, menus, linkTypes, linkType } = this.state;
    const { visible, hideModal } = this.props;
    const menuList = [
      { value: 0, label: this.props.menuName },
      ...menus.filter((e) => e.name !== name).map((e) => ({ value: e.id, label: e.name })),
    ];
    if (this.props.itemForEdit) {
      return (
        <div
          className={`addTeamModal d-flex justify-content-center align-items-center ${
            visible ? "visible" : ""
          }`}>
          <div className={` errorPopup ${this.state.showErrorPopup ? "popupShown" : ""}`}>
            <div className='content py-20 px-10 d-flex justify-content-between flex-column'>
              <h3 className='f-s-18 f-w-4 uppercase text-center'>
                Problemi prilikom izmjene stavke
              </h3>
              {this.state.errors.length > 0 ? (
                <ul className='mt-30'>
                  {this.state.errors.map((e, index) => {
                    return (
                      <li key={index} className='f-s-16 text-center'>
                        {e}
                      </li>
                    );
                  })}
                </ul>
              ) : (
                <ul className='mt-30'>
                  {this.state.responseErrors.map((e) => {
                    return e.map((el, index) => (
                      <li key={index} className='f-s-16 text-center'>
                        {e}
                      </li>
                    ));
                  })}
                </ul>
              )}
              <div className='button text-center mt-30'>
                <CustomButton onClick={this.removePopup}>Zatvori</CustomButton>
              </div>
            </div>
          </div>
          <div className='addTeamContent py-30 px-30'>
            <h4 className='text-color-primary f-s-20 lh text-center mb-20'>
              Izmjeni stavku menija
            </h4>
            <div className='row mt-30'>
              <div className='col-md-6'>
                <label
                  htmlFor='linkType'
                  className='f-s-14 text-color-font-l'
                  style={{
                    color: "#7ea5cb",
                    marginBottom: "5px",
                    display: "block",
                  }}>
                  Naziv
                </label>
                <CustomInput
                  // label="Naziv"
                  value={name}
                  handleChange={this.handleInput}
                  name='name'
                  index={0}
                  counter={false}
                />
              </div>
              <div className='col-md-6'>
                <label
                  htmlFor='linkType'
                  className='f-s-14 text-color-font-l'
                  style={{
                    color: "#7ea5cb",
                    marginBottom: "5px",
                    display: "block",
                  }}>
                  Tip linka
                </label>
                <SearchableDropdown
                  data={linkTypes}
                  placeholder='Tip linka'
                  name='currentLinkType'
                  handleChange={this.handleInput}
                  value={this.state.currentLinkType}
                />
              </div>
            </div>
            <div
              className={`row ${
                this.state.currentLinkType.value !== null && this.state.currentLinkType.value !== 3
                  ? "mt-30"
                  : ""
              }`}>
              <div className='col-12'>{this.renderLink(this.state.currentLinkType.value)}</div>
            </div>
            <div className='row mt-30'>
              <div className='col-12'>
                <label
                  htmlFor='linkType'
                  className='f-s-14 text-color-font-l'
                  style={{
                    color: "#7ea5cb",
                    marginBottom: "5px",
                    display: "block",
                  }}>
                  Roditeljski meni
                </label>
                <SearchableDropdown
                  data={menuList}
                  placeholder='Roditeljski meni'
                  name='currentParentID'
                  handleChange={this.handleInput}
                  value={this.state.currentParentID}
                />
              </div>
            </div>
            <div className='row mt-40'>
              <div className='col-lg-12 d-flex justify-content-center'>
                <CustomButton className='mr-30' onClick={(e) => this.handleSubmit(e)}>
                  Izmjeni stavku
                </CustomButton>
                <CustomButton className='red' onClick={hideModal}>
                  Odustani
                </CustomButton>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return "";
    }
  }
}

export default EditMenuItem;
