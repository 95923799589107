import React, { useState } from "react";
import JoditEditor from "jodit-react";

const TextEditor = ({ value, handleInput, name, index }) => {
  const config = {
    readonly: false,
    buttons:
      "source,bold,strikethrough,underline,italic,eraser,|,font,fontsize,superscript,subscript,|,ul,ol,|,outdent,indent,|,brush,paragraph,,,,,link,,align,undo,redo",
    spellcheck: false,
    showXPathInStatusbar: false,
    // defaultActionOnPaste: "insert_only_text",
    askBeforePasteHTML: false,
    askBeforePasteFromWord: false,
    showCharsCounter: false,
    showWordsCounter: false,
    placeholder: "",
    colorPickerDefaultTab: "color",
    colors: {
      greyscale: [
        "#434343",
        "#7e7e7e",
        "#FFFFFF",
        "#FFFFFF",
        "#FFFFFF",
        "#FFFFFF",
        "#FFFFFF",
        "#FFFFFF",
        "#FFFFFF",
        "#FFFFFF",
      ],
      palette: [
        "#004191",
        "#dcb953",
        "#FFFFFF",
        "#FFFFFF",
        "#FFFFFF",
        "#FFFFFF",
        "#FFFFFF",
        "#FFFFFF",
        "#FFFFFF",
        "#FFFFFF",
      ],
      full: [
        "#FFFFFF",
        "#FFFFFF",
        "#FFFFFF",
        "#FFFFFF",
        "#FFFFFF",
        "#FFFFFF",
        "#FFFFFF",
        "#FFFFFF",
        "#FFFFFF",
        "#FFFFFF",
        "#DD7E6B",
        "#EA9999",
        "#F9CB9C",
        "#FFE599",
        "#B6D7A8",
        "#A2C4C9",
        "#A4C2F4",
        "#9FC5E8",
        "#B4A7D6",
        "#D5A6BD",
        "#CC4125",
        "#E06666",
        "#F6B26B",
        "#FFD966",
        "#93C47D",
        "#76A5AF",
        "#6D9EEB",
        "#6FA8DC",
        "#8E7CC3",
        "#C27BA0",
        "#A61C00",
        "#CC0000",
        "#E69138",
        "#F1C232",
        "#6AA84F",
        "#45818E",
        "#3C78D8",
        "#3D85C6",
        "#674EA7",
        "#A64D79",
        "#85200C",
        "#990000",
        "#B45F06",
        "#BF9000",
        "#38761D",
        "#134F5C",
        "#1155CC",
        "#0B5394",
        "#351C75",
        "#733554",
        "#5B0F00",
        "#660000",
        "#783F04",
        "#7F6000",
        "#274E13",
        "#0C343D",
        "#1C4587",
        "#073763",
        "#20124D",
        "#4C1130",
      ],
    },
  };

  const handleCounter = (val) => {
    document.getElementById(`${name}-${index}`).innerHTML = val;
  };

  return (
    <div className='textEditor'>
      <JoditEditor
        value={value}
        config={config}
        tabIndex={1} // tabIndex of textarea
        onBlur={(newContent) => handleInput(name, newContent, index)} // preferred to use only this option to update the content for performance reasons
        // onBlur={(newContent) => handleInput(name, newContent, index)} // preferred to use only this option to update the content for performance reasons
        // onChange={(newContent) => handleCounter(newContent.length)}
      />
      <span className='count f-s-10 px-5'>
        Broj karaktera: <b id={`${name}-${index}`}>{value ? value.length : 0}</b>
      </span>
    </div>
  );
};
export default TextEditor;
