import { CustomButton, LinkButton } from "../../components/Buttons/Button";
import React, { Component } from "react";
import {
  addDiscount,
  addVideo,
  deleteDiscount,
  deleteTeam,
  deleteVideo,
  editDiscount,
  editVideo,
  getAllDiscounts,
  getAllTeams,
  getAllVideos
} from "../../functions/postFunctions";
import {
  addDiscountValidation,
  addVideoValidation
} from "../../functions/validateFunctions";
import {
  dateLocalisation,
  dateLocalisationShort,
  formatDateForSafari
} from "../../functions/functions";

import { CustomInput } from "../../components/Form/Inputs/Inputs";
import DatePicker from "react-date-picker";
import Table from "../../components/Table/Table";

class DiscountsPage extends Component {
  state = {
    videos: [],
    name: "",
    discount: "",
    date_from: new Date(),
    date_to: new Date(),
    showAddTeam: false,
    showEditTeam: false,
    teamForEdit: null,
    errors: [],
    showErrorPopup: false,
    responseErrors: [],
    addingVideo: false,
    theads: ["Naziv", "Popust", "Datum od", "Datum do", "Akcije"],
    discounts: []
  };

  componentDidMount() {
    this.props.setBreadcrumbs("Popusti");
    getAllDiscounts().then(res => {
      if (res.success) {
        this.setState({ discounts: res.discounts });
      }
    });
  }

  handleDeletePage = id => {
    deleteDiscount(id, this.props.token).then(res => {
      if (res.success) {
        this.setState({ discounts: res.discounts });
      }
    });
  };

  showModal = (name, id) => {
    let team;
    if (id) {
      const { teams } = this.state;
      team = teams.find(e => e.id === id);
    }
    this.setState({ [name]: true, teamForEdit: team });
  };

  hideModal = () => {
    this.setState({
      showAddTeam: false,
      showEditTeam: false,
      teamForEdit: null
    });
  };

  addTeamToList = team => {
    this.setState(prevState => ({
      teams: [...prevState.teams, team],
      showAddTeam: false
    }));
  };

  refreshList = () => {
    getAllTeams().then(res =>
      this.setState({
        teams: res.teams,
        showEditTeam: false,
        teamForEdit: null
      })
    );
  };

  handleInput = (name, value) => {
    this.setState({
      [name]: value
    });
  };

  handleInputMultiple = (name, value, index) => {
    this.setState(({ discounts }) => ({
      discounts: [
        ...discounts.slice(0, index),
        {
          ...discounts[index],
          [name]: value
        },
        ...discounts.slice(index + 1)
      ]
    }));
  };

  handleAddVideo = () => {
    const { name, discount, date_from, date_to } = this.state;
    const errors = addDiscountValidation(this.state);
    if (errors.length === 0) {
      this.setState({ addingVideo: true });
      addDiscount(
        { name, discount, date_from, date_to },
        this.props.token
      ).then(res => {
        if (res.success) {
          this.setState({
            link: "",
            title: "",
            date: new Date(),
            discounts: res.discounts,
            addingVideo: false
          });
        } else {
          this.setState({
            responseErrors: res.errors,
            showErrorPopup: true,
            addingVideo: false
          });
        }
      });
    } else {
      this.setState({ errors, showErrorPopup: true, addingVideo: false });
    }
  };

  removePopup = () => {
    this.setState({ showErrorPopup: false });
  };

  handleUpdateDiscount = id => {
    const discountForEdit = this.state.discounts.find(e => e.id === id);
    const { name, discount, date_from, date_to } = discountForEdit;
    const errors = addDiscountValidation(discountForEdit);
    if (errors.length === 0) {
      editDiscount(
        { id, name, discount, date_from, date_to },
        this.props.token
      ).then(res => {
        if (res.success) {
          this.setState({
            discounts: res.discounts
          });
        } else {
          this.setState({ responseErrors: res.errors, showErrorPopup: true });
        }
      });
    } else {
      this.setState({ errors, showErrorPopup: true });
    }
  };

  render() {
    const { videos, teamForEdit, theads, discounts } = this.state;
    let server;
    if (process.env.NODE_ENV === "development") {
      server = "http://127.0.0.8/";
    } else {
      server = "https://admin.fk-buducnost.me/";
    }
    return (
      <div>
        <div
          className={` errorPopup ${
            this.state.showErrorPopup ? "popupShown" : ""
          }`}
        >
          <div className="content py-20 px-10 d-flex justify-content-between flex-column">
            <h3 className="f-s-18 f-w-4 uppercase text-center">
              Problemi prilikom kreiranja popusta
            </h3>
            {this.state.errors.length > 0 ? (
              <ul className="mt-30">
                {this.state.errors.map((e, index) => {
                  return (
                    <li key={index} className="f-s-16 text-center">
                      {e}
                    </li>
                  );
                })}
              </ul>
            ) : (
              <ul className="mt-30">
                {this.state.responseErrors.map(e => {
                  return e.map((el, index) => (
                    <li key={index} className="f-s-16 text-center">
                      {e}
                    </li>
                  ));
                })}
              </ul>
            )}
            <div className="button text-center mt-30">
              <CustomButton onClick={this.removePopup}>Zatvori</CustomButton>
            </div>
          </div>
        </div>
        <div className="pageTop mb-40">
          {this.state.addingVideo ? (
            <div className="content py-20 px-10 d-flex justify-content-center flex-column">
              <h3 className="f-s-22 f-w-4 uppercase text-center text-color-primary">
                Dodavanje popusta
              </h3>
              <div className="optimization loader d-flex justify-content-center align-items-center">
                <div className="fancy-spinner">
                  <div className="ring" />
                  <div className="ring" />
                  <div className="dot" />
                </div>
              </div>
            </div>
          ) : (
            <div className="row">
              <div className="row" style={{ width: "100%" }}>
                <div className="col-md-2">
                  <p className="f-s-14 text-color-primary">Naziv</p>
                </div>
                <div className="col-md-2">
                  <p className="f-s-14 text-color-primary">Popust</p>
                </div>
                <div className="col-md-2">
                  <p className="f-s-14 text-color-primary">Datum od</p>
                </div>
                <div className="col-md-2">
                  <p className="f-s-14 text-color-primary">Datum do</p>
                </div>
              </div>
              <div className="row" style={{ width: "100%" }}>
                <div className="col-md-2">
                  <CustomInput
                    value={this.state.name}
                    handleChange={this.handleInput}
                    name="name"
                    realTime
                  />
                </div>
                <div className="col-md-2">
                  <CustomInput
                    value={this.state.discount}
                    handleChange={this.handleInput}
                    name="discount"
                    type="number"
                  />
                </div>
                <div className="col-md-2">
                  <DatePicker
                    onChange={e => this.handleInput("date_from", e, 0)}
                    value={this.state.date_from}
                    locale={"bs-BS"}
                  />
                </div>
                <div className="col-md-2">
                  <DatePicker
                    onChange={e => this.handleInput("date_to", e, 0)}
                    value={this.state.date_to}
                    locale={"bs-BS"}
                  />
                </div>
                <div className="col-md-3">
                  <CustomButton onClick={this.handleAddVideo}>
                    Dodaj popust
                  </CustomButton>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="row">
          <Table theads={theads}>
            {discounts.map((e, index) => {
              return (
                <tr key={index}>
                  <td className="td px-10">
                    <CustomInput
                      value={e.name}
                      handleChange={this.handleInputMultiple}
                      name="name"
                      index={index}
                    />
                  </td>
                  <td className="td px-10">
                    <CustomInput
                      value={e.discount}
                      handleChange={this.handleInputMultiple}
                      name="discount"
                      type="number"
                      index={index}
                    />
                  </td>
                  <td className="td px-10">
                    <span className="f-s-16">
                      <DatePicker
                        onChange={e =>
                          this.handleInputMultiple("date_from", e, index)
                        }
                        value={new Date(e.date_from)}
                        locale={"bs-BS"}
                      />
                    </span>
                  </td>
                  <td className="td px-10">
                    <span className="f-s-16">
                      <DatePicker
                        onChange={e =>
                          this.handleInputMultiple("date_to", e, index)
                        }
                        value={new Date(e.date_to)}
                        locale={"bs-BS"}
                      />
                    </span>
                  </td>
                  <td className="td px-10" style={{ minWidth: "220px" }}>
                    <CustomButton
                      className="mr-20"
                      onClick={() => this.handleUpdateDiscount(e.id)}
                    >
                      Izmjeni
                    </CustomButton>
                    <CustomButton
                      onClick={() => this.handleDeletePage(e.id)}
                      className="red"
                    >
                      Izbriši
                    </CustomButton>
                  </td>
                </tr>
              );
            })}
          </Table>
        </div>
      </div>
    );
  }
}

export default DiscountsPage;
