import { CustomButton, LinkButton } from "../../components/Buttons/Button";
import React, { Component } from "react";
import {
  deleteProduct,
  getAllProductCategory,
  getAllProducts,
  togglePublished,
  toggleSpecialOffer,
  updateProductCode,
  getProduct,
  addProduct,
} from "../../functions/postFunctions";

import { CustomInput } from "../../components/Form/Inputs/Inputs";
import Pagination from "react-js-pagination";
import SearchableDropdownMultiple from "../../components/Form/Dropdown/SearchableDropdownMultiple";
import SortProducts from "../../components/SortProducts/SortProducts";
import Table from "../../components/Table/Table";

class ProductsPage extends Component {
  state = {
    theads: ["Cover", "Naziv", "Šifra proizvoda", "Specijalna ponuda", "Označi", "Objavljeno", "Akcije"],
    products: [],
    removeLoadMore: true,
    page: 1,
    categoryList: [],
    currentCategory: null,
    sortProductsModal: false,
    sortableCategories: [],
    activePage: 1,
    max: 0,
    product_name_search: this.props.searched,
    // prev_selected_category:this.props.selectedCategory
  };

  componentDidMount() {
    this.props.setBreadcrumbs("Proizvodi");
    if (parseInt(this.props.match.params.page) < 1) {
      this.props.history.push("1");
    }
    let categories = [];
    let sortableCategories = [];
    const handleRecursion = (arr) => {
      arr.forEach((e) => {
        categories.push({ value: e.id, label: e.name });
        if (e.children.length > 0) {
          handleRecursion(e.children);
        } else {
          if (e.id !== 290) {
            sortableCategories.push(e);
          }
        }
      });
    };

    getAllProductCategory().then((res) => {
      if (res.success) {
        handleRecursion(res.categories);
        const categories = this.findAllMenus(res.categories);
        this.setState({
          categoryList: categories,
          currentCategory: this.props.selectedCategory,
          sortableCategories,
          activePage: parseInt(this.props.match.params.page),
        });
      }
    });

    let temp=[];

    for(let i=0; i<20; i++){
      let data = {};
      data = {
        checked: false
      }

      temp.push(data);
    }

  }

  findAllMenus = (array) => {
    let menus = [];
    const handleRecursion = (arr) => {
      arr.forEach((e) => {
        menus.push(e);
        if (e.children.length > 0) {
          handleRecursion(e.children);
        }
      });
    };
    handleRecursion(array);
    return menus;
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.currentCategory !== this.state.currentCategory && this.state.currentCategory) {
      this.loadNews();
    }
    if (prevProps.match.params.page !== this.props.match.params.page) {
      this.setState({ activePage: parseInt(this.props.match.params.page) });
      this.loadMoreNews();
    }

    if (prevState.product_code !== this.state.product_code) {
      this.loadNews();
      this.props.history.push("1");
    }


  }

  handleDeletePage = (id) => {
    const { products } = this.state;
    const newProducts = products.filter((e) => e.id !== id);
    deleteProduct(id, this.props.token).then((res) => {
      if (res.success) {
        this.setState({ products: newProducts });
      }
    });
  };

  loadNews = () => {
    const { currentCategory, product_name_search } = this.state;
    getAllProducts(
      20,
      (parseInt(this.props.match.params.page) - 1) * 20,
      currentCategory.value,
      product_name_search !== "" ? product_name_search : null
    ).then((res) => {
      if (res.success) {
        console.log(res)
        this.setState({
          products: res.products,
          max: res.products.length > 0 ? res.total : 0,
          page: 1,
        });
      
        
      
      }
    });
  };

  loadMoreNews = () => {
    const { page, currentCategory, product_name_search } = this.state;
    getAllProducts(
      20,
      (parseInt(this.props.match.params.page) - 1) * 20,
      currentCategory.value,
      product_name_search !== "" ? product_name_search : null
    ).then((res) => {
      if (res.success) {
        
        this.setState(({ products, page }) => ({
          products: res.products,
          page: page + 1,
          max: res.products.length > 0 ? res.total : 0,
        }));

      }
    });
  };

  handleInput = (name, value, index) => {
    if (name === "currentCategory") {
      this.props.setSelectedCategory(value);
      this.props.history.push("1");
    }
    if (name === "product_name_search") {
      this.setState(
        {
          [name]: value,
        },
        () => {
          this.loadNews(true);
        }
      );
    }
    if (name !== "product_code") {
      this.setState({
        [name]: value,
      });
    } else {
      this.setState(
        ({ products }) => ({
          products: [
            ...products.slice(0, index),
            {
              ...products[index],
              [name]: value,
            },
            ...products.slice(index + 1),
          ],
        }),
        () => {
          const product = this.state.products.find((_, i) => i === index);
          const data = { id: product.id, product_code: product.product_code };
          this.handleUpdateProductCode(data);
        }
      );
    }
  };

  timeout = null;
  handleUpdateProductCode = (data) => {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      updateProductCode(data, this.props.token).then((res) => console.log(res));
    }, 1000);
  };

  toggleModal = () => {
    this.setState(
      ({ sortProductsModal }) => ({
        sortProductsModal: !sortProductsModal,
      }),
      () => {
        if (!this.state.sortProductsModal) {
          this.loadNews();
        }
      }
    );
  };

  toggleModalCategory = () => {
    this.setState({ modalCategory: !this.state.modalCategory})
  }

  handleCheckboxChange = (id, index) => {
    this.setState(
      ({ products }) => ({
        products: [
          ...products.slice(0, index),
          {
            ...products[index],
            special_offer: !products[index].special_offer,
          },
          ...products.slice(index + 1),
        ],
      }),
      () => {
        toggleSpecialOffer(id, this.state.products[index].special_offer, this.props.token).then(
          (res) => null
        );
      }
    );
  };

  handlePublishedChange = (id, index) => {
    this.setState(
      ({ products }) => ({
        products: [
          ...products.slice(0, index),
          {
            ...products[index],
            published: !products[index].published,
          },
          ...products.slice(index + 1),
        ],
      }),
      () => {
        togglePublished(id, this.state.products[index].published, this.props.token).then(
          (res) => null
        );
      }
    );
  };

  handlePageChange = (pageNumber) => {
    this.props.history.push(`${pageNumber}`);
  };

  redirectAddProduct = () => {
    this.props.history.push("/dodaj-proizvod");
  };
  importAll = (r) => {
    return r.keys().map(r);
  };

  duplicateHandler = async (id) => {
    try {
      const res = await getProduct(id);

      const data = new FormData();
      const { category_id, name, amount, unit, price, product_code, description } = res.product;
      const images = res.images.map((img) => img.image);

      const items = {
        category_id,
        name,
        amount,
        unit,
        price,
        product_code: product_code || "",
        description: description || "",
        sizes: JSON.stringify(res.sizes),
        specifications: JSON.stringify(res.specifications),
        filters: JSON.stringify(res.filters.filter((e) => e.id !== 0)),
      };
      Object.keys(items).forEach((e) => {
        data.append(e, items[e]);
      });
      const config = {
        onUploadProgress: (progressEvent) => {
          const completed = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          this.setState({
            completed,
            uploadComplete: completed === 100,
          });
        },
      };
      addProduct(data, this.props.token, config).then((res) => {
        if (res.success) {
          // this.props.history.push(`/proizvodi/${localStorage.getItem("page")}`);
          this.loadNews();
        } else {
          console.log("error");
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  render() {
    const { theads, products, categoryList, currentCategory, sortProductsModal } = this.state;
    localStorage.setItem("page", this.state.activePage);
    let options = [];
    let option = null;
    categoryList.forEach((e) => {
      if (e.level === 0) {
        option = null;
        option = {
          label: e.name,
          options: [{ value: e.id, label: e.name }],
        };
        if (option) {
          options.push(option);
        }
      } else {
        option.options.push({ value: e.id, label: e.name });
      }
    });
    const categories = [
      {
        value: 0,
        label: "Svi proizvodi",
      },
      ...options,
    ];

    return (
      <div>
        <SortProducts
          hideModal={this.toggleModal}
          visible={sortProductsModal}
          sortableCategories={options.filter((e) => e.label !== "Abacus")}
          token={this.props.token}
        />
        
        <div className='pageTop mb-40'>
          <div className='row'>
            <div className='col-md-3'>
              <SearchableDropdownMultiple
                data={categories}
                placeholder='Kategorija'
                name='currentCategory'
                handleChange={this.handleInput}
                value={this.props.selectedCategory}
              />
            </div>
            <div className='col-md-3'>
              <CustomInput
                label='Naziv ili šifra'
                value={this.state.product_name_search}
                handleChange={this.handleInput}
                name='product_name_search'
                index={0}
                fast
              />
            </div>
            <div className='col-md-6'>
              <CustomButton onClick={this.redirectAddProduct} className='mr-20 green'>
                Dodaj proizvod
              </CustomButton>
              <CustomButton onClick={this.toggleModal} className='mr-20'>Sortiraj proizvode</CustomButton>
            </div>
          </div>
        </div>
        <Table theads={theads}>
          {products.map((e, index) => {
            return (
              <tr key={index}>
                <td className='td px-10'>
                  <img
                    src={`${
                      e.cover && e.cover.substring(0, 4) === "http" ? e.cover : `/${e.cover}`
                    }`}
                    alt={e.slug}
                    style={{ maxHeight: "50px" }}
                  />
                </td>
                <td className='td px-10'>
                  <span className='f-s-16'>{e.name}</span>
                </td>
                <td className='td px-10'>
                  <CustomInput
                    label='Šifra'
                    value={e.product_code || ""}
                    handleChange={this.handleInput}
                    name='product_code'
                    index={index}
                    fast
                  />
                </td>
                <td className='td px-10'>
                  <span className='f-s-16'>
                    <input
                      type='checkbox'
                      checked={this.state.products[index].special_offer}
                      onChange={() => this.handleCheckboxChange(e.id, index)}
                    />
                  </span>
                </td>
                <td className='td px-10'>
                  <span className='f-s-16'>
                    <input
                      type='checkbox'
                      checked={this.state.products[index].published}
                      onChange={() => this.handlePublishedChange(e.id, index)}
                    />
                  </span>
                </td>
                <td className='td px-10' style={{ minWidth: "220px" }}>
                  <LinkButton
                    onClick={() => {
                      this.props.setSearched(this.state.product_name_search);
                      this.props.setSelectedCategory(this.state.currentCategory);
                    }}
                    href={`/izmjeni-proizvod/${e.id}`}
                    className='mr-20'>
                    Izmjeni
                  </LinkButton>
                  <CustomButton onClick={() => this.handleDeletePage(e.id)} className='red'>
                    Izbriši
                  </CustomButton>
                  <CustomButton
                    onClick={() => {
                      this.duplicateHandler(e.id);
                    }}
                    className='green ml-20'>
                    Dupliraj
                  </CustomButton>
                </td>
              </tr>
            );
          })}
        </Table>
        <div className='wrapper mt-40 d-flex justify-content-center'>
          <Pagination
            hideDisabled
            activePage={this.state.activePage}
            itemsCountPerPage={20}
            totalItemsCount={this.state.max}
            pageRangeDisplayed={5}
            onChange={this.handlePageChange}
          />
        </div>
      </div>
    );
  }
}

export default ProductsPage;
