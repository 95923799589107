import { CustomButton, LinkButton } from "../../../components/Buttons/Button";
import {
  CustomInput,
  CustomTextArea
} from "../../../components/Form/Inputs/Inputs";
import React, { Component } from "react";
import {
  addImages,
  addImagesProduct,
  addProduct,
  getAllCategories,
  getAllDiscounts,
  getAllProductCategory,
  getAllProducts,
  getAllProductsRabat,
  getAllSizes,
  getCustomer,
  getProduct,
  updateCustomersRabat,
  updateProduct
} from "../../../functions/postFunctions";
import {
  addPageValidiation,
  addProductValidation
} from "../../../functions/validateFunctions";

import SearchableDropdown from "../../../components/Form/Dropdown/SearchableDropdown";
import SearchableDropdownMultiple from "../../../components/Form/Dropdown/SearchableDropdownMultiple";
import SortableGrid from "../../../components/SortableGrid/SortableGrid";
import TextEditor from "../../../components/TextEditor/TextEditor";

class EditCustomer extends Component {
  state = {
    id: 0,
    language_id: 1,
    name: "",
    description: "",
    content: "",
    product_code: "",
    date: new Date(),
    content_right: "",
    errors: [],
    showErrorPopup: false,
    showWarningPopup: false,
    images: null,
    active: "content",
    listOfImages: [],
    stepOne: false,
    edit: true,
    completed: 0,
    showUploadPopup: false,
    uploadComplete: false,
    rightSide: false,
    categoryList: [],
    category_id: 0,
    unit: "",
    price: "",
    uploadedImages: [],
    amount: "",
    sizes: [],
    allSizes: [],
    discounts: [],
    discount: { value: 0, label: "Bez popusta" },
    specifications: [],
    discount_type: null,
    products: []
  };

  componentDidMount() {
    this.props.setBreadcrumbs("Izmjeni klijenta");
    getCustomer(this.props.match.params.id).then(res => {
      if (res.success) {
        this.setState(
          {
            ...res.customer,
            specifications: res.rabat.map(e => {
              return {
                product_id: e.product_id,
                discount_id: e.discount_id,
                discount_type: 0
              };
            })
          },
          () => {
            if (this.state.specifications.length > 0) {
              getAllProductsRabat().then(res => {
                this.setState({
                  products: res.products.map(e => ({
                    label: e.name,
                    value: e.id
                  }))
                });
              });
            }
          }
        );
      } else {
        this.props.history.push(`/proizvodi/${localStorage.getItem("page")}`);
      }
    });
    getAllDiscounts().then(res => {
      this.setState({
        discounts: res.discounts.map(e => ({ label: e.name, value: e.id }))
      });
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.errors != this.state.errors && this.state.errors.length > 0) {
      this.setState({ showErrorPopup: true });
    }
    if (prevState.stepOne !== this.state.stepOne) {
      const item = document.querySelector(".gallery");
      this.navigationMove(item, "gallery");
    }
  }

  handleInput = (name, value, index) => {
    if (name === "size" || name === "amount") {
      this.setState(({ sizes }) => ({
        sizes: [
          ...sizes.slice(0, index),
          {
            ...sizes[index],
            [name]: value
          },
          ...sizes.slice(index + 1)
        ]
      }));
    } else if (
      name === "product_id" ||
      name === "discount_id" ||
      name === "discount_type"
    ) {
      this.setState(
        ({ specifications }) => ({
          specifications: [
            ...specifications.slice(0, index),
            {
              ...specifications[index],
              [name]: value
            },
            ...specifications.slice(index + 1)
          ]
        }),
        () => {
          if (name === "discount_type" && value === 0) {
            getAllProductsRabat().then(res => {
              this.setState({
                products: res.products.map(e => ({
                  label: e.name,
                  value: e.id
                }))
              });
            });
          } else if (name === "discount_type" && value === 1) {
            getAllProductCategory().then(res =>
              this.setState({ products: [] }, () => {
                handleRecursion(res.categories);
              })
            );

            const handleRecursion = data => {
              if (data) {
                data.forEach(e => {
                  if (e.children.length > 0) {
                    handleRecursion(e.children);
                  } else {
                    this.setState(({ products }) => ({
                      products: [...products, { label: e.name, value: e.id }]
                    }));
                  }
                });
              }
            };
          }
        }
      );
    } else {
      this.setState({ [name]: value });
    }
  };

  handleFileInput = event => {
    this.setState({ images: [...event] });
  };

  removePopup = () => {
    this.setState({ showErrorPopup: false, showWarningPopup: false });
  };

  handleSubmit = event => {
    event.preventDefault();
    const { active } = this.state;
    const errors = addProductValidation(this.state, true);
    if (errors.length === 0) {
      if (active === "content") {
        this.handleUpdateProduct();
      } else {
        this.handleAddImages();
      }
    } else {
      this.setState({ errors });
    }
  };

  handleUpdateProduct = () => {
    const { id, specifications } = this.state;
    const spec = specifications.map(e => {
      let product_id = e.product_id;
      let discount_id = e.discount_id;
      let discount_type = e.discount_type;
      if (typeof e.product_id !== "number") {
        product_id = e.product_id.value;
      }
      if (typeof e.discount_id !== "number") {
        discount_id = e.product_id.value;
      }
      if (typeof e.discount_type !== "number") {
        discount_type = e.product_id.value;
      }

      return { product_id, discount_id, discount_type };
    });
    const data = {
      id,
      specifications: JSON.stringify(spec)
    };
    updateCustomersRabat(data, this.props.token).then(res => {
      if (res.success) {
        this.props.history.push(`/klijenti`);
      } else {
        this.setState({ showErrorPopup: true });
      }
    });
  };

  handleAddSize = () => {
    this.setState(({ sizes }) => ({
      sizes: [...sizes, { amount: "", size: "" }]
    }));
  };

  handleRemoveSize = index => {
    const sizes = this.state.sizes.filter((_, i) => i !== index);
    this.setState({ sizes: [] }, () => this.setState({ sizes }));
  };

  addSpecification = () => {
    const specification = {
      customer_id: this.state.id,
      product_id: 0,
      discount_id: 0,
      discount_type: null
    };

    this.setState(({ specifications }) => ({
      specifications: [...specifications, specification]
    }));
  };

  deleteSpecification = index => {
    const specifications = this.state.specifications.filter(
      (_, i) => i !== index
    );
    this.setState({ specifications: [] }, () =>
      this.setState({ specifications })
    );
  };

  render() {
    return (
      <div className="wrapper">
        <div className="contentWrapper">
          <div
            className={` errorPopup ${
              this.state.showErrorPopup ? "popupShown" : ""
            }`}
          >
            <div className="content py-20 px-10 d-flex justify-content-between flex-column">
              <h3 className="f-s-18 f-w-4 uppercase text-center">
                Problemi prilikom dodavanja rabata
              </h3>
              <ul className="mt-30">
                {this.state.errors.map((e, index) => {
                  return (
                    <li key={index} className="f-s-16 text-center">
                      {e}
                    </li>
                  );
                })}
              </ul>
              <div className="button text-center mt-30">
                <CustomButton onClick={this.removePopup}>Zatvori</CustomButton>
              </div>
            </div>
          </div>
          <div className="info">
            <div className="row mt-30">
              <div className="col-12">
                <span className="f-s-16">
                  <b>Ime: </b>
                  {this.state.name}
                </span>
              </div>
              <div className="col-12">
                <span className="f-s-16">
                  <b>Ime kompanije: </b>
                  {this.state.company_name}
                </span>
              </div>
              <div className="col-12">
                <span className="f-s-16">
                  <b>Email: </b>
                  {this.state.email}
                </span>
              </div>
              <div className="col-12">
                <span className="f-s-16">
                  <b>Telefon: </b>
                  {this.state.phone}
                </span>
              </div>
              <div className="col-12">
                <span className="f-s-16">
                  <b>Adresa: </b>
                  {this.state.address}
                </span>
              </div>
              <div className="col-12">
                <span className="f-s-16">
                  <b>Grad: </b>
                  {this.state.city}
                </span>
              </div>
              <div className="col-12">
                <span className="f-s-16">
                  <b>PIB: </b>
                  {this.state.pib}
                </span>
              </div>
              <div className="col-12">
                <span className="f-s-16">
                  <b>PDV: </b>
                  {this.state.PDV}
                </span>
              </div>
            </div>
          </div>
          <form onSubmit={e => e.preventDefault()}>
            {this.state.specifications.map((e, index) => {
              return (
                <div className="row mt-30" key={index}>
                  <div className="col-md-3">
                    <SearchableDropdown
                      placeholder="Tip popusta"
                      data={[
                        { label: "Proizvod", value: 0 },
                        { label: "Kategorija proizvoda", value: 1 }
                      ]}
                      handleChange={this.handleInput}
                      name="discount_type"
                      index={index}
                      value={{ label: "Proizvod", value: 0 }}
                    />
                  </div>
                  {e.discount_type !== null && (
                    <div className="col-md-4">
                      <SearchableDropdown
                        placeholder={
                          this.state.discount_type === 1
                            ? "Izaberi kategoriju"
                            : "Izaberi proizvod"
                        }
                        value={this.state.products.find(
                          el => el.value === e.product_id
                        )}
                        handleChange={this.handleInput}
                        name="product_id"
                        index={index}
                        data={this.state.products}
                      />
                    </div>
                  )}
                  <div className="col-md-3">
                    <SearchableDropdown
                      placeholder="Popust"
                      value={this.state.discounts.find(
                        el => el.value === e.discount_id
                      )}
                      handleChange={this.handleInput}
                      name="discount_id"
                      index={index}
                      data={this.state.discounts}
                    />
                  </div>
                  <div className="col-md-2">
                    <CustomButton
                      onClick={e => this.deleteSpecification(index)}
                      className="mr-20 red"
                    >
                      Izbriši
                    </CustomButton>
                  </div>
                </div>
              );
            })}
            <div className="row mt-30">
              <div className="col-12">
                <CustomButton
                  onClick={this.addSpecification}
                  className="mr-20 green"
                >
                  Dodaj rabat
                </CustomButton>
              </div>
            </div>
          </form>
        </div>

        <div className="row mt-30">
          <div className="col-12 d-flex justify-content-end">
            <div className="row mt-30">
              <div className="col-12 d-flex justify-content-end">
                <CustomButton
                  onClick={e => this.handleSubmit(e)}
                  className="mr-20"
                >
                  {this.state.active === "content"
                    ? "Ažuriraj klijenta"
                    : "Dodaj fotografije"}
                </CustomButton>
                <LinkButton className="red" href="/proizvodi/1">
                  Odustani od izmjena
                </LinkButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default EditCustomer;
