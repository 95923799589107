import React, { Component } from "react";
import {
  SortableContainer,
  SortableElement,
  arrayMove
} from "react-sortable-hoc";
import { ListIcon } from "../icons/icons";
import { sortMenus, sortPartners } from "../../functions/postFunctions";
import { CustomButton, LinkButton } from "../Buttons/Button";

class SortableListPartners extends Component {
  state = {
    items: []
  };

  onSortEnd = ({ oldIndex, newIndex }) => {
    this.setState(
      {
        items: arrayMove(this.state.items, oldIndex, newIndex)
      },
      () => this.handleSortMenus()
    );
  };

  componentDidMount() {
    this.setState({ items: this.props.items });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.items !== this.props.items) {
      this.setState({ items: this.props.items });
    }
  }

  handleSortMenus = () => {
    const type = this.props.type || 0;
    sortPartners(this.state.items, type, this.props.token).then(res => null);
  };

  render() {
    let server;
    if (process.env.NODE_ENV === "development") {
      server = "http://127.0.0.1:8000/";
    } else {
      server = "https://admin.bajkeraj.me/";
    }
    const SortableItem = SortableElement(({ item, index }) => (
      <li
        className={`level-${item.level} mt-20`}
        style={{ paddingLeft: `${item.level * 20}px`, listStyle: "none" }}
        key={index}
      >
        <span className="f-s-16 d-flex align-items-center justify-content-between">
          <span className="left d-flex align-items-center">
            <ListIcon className="fill mr-10" />
            {item.image !== "" ? (
              <div
                className="bg-primary pa-20 d-flex justify-content-center align-items-center"
                style={{ minWidth: "200px" }}
              >
                <img
                  src={`${server}/${item.image}`}
                  alt={""}
                  style={{ maxHeight: "80px" }}
                />
              </div>
            ) : (
              <span className="f-s-24 text-color-white">X</span>
            )}
            <span className="ml-20 ">{item.link}</span>
          </span>
          <span className="right d-flex align-items-center">
            <CustomButton
              className="mr-20"
              onClick={() =>
                this.props.showModal("showEditCompetition", item.id)
              }
            >
              Izmjeni
            </CustomButton>
            <CustomButton
              className="red"
              onClick={() => this.props.deleteMenuItem(item.id)}
            >
              Izbriši
            </CustomButton>
          </span>
        </span>
      </li>
    ));

    const SortableList = SortableContainer(({ items }) => {
      return (
        <ul className="pt-40">
          {items.map((item, index) => (
            <React.Fragment key={index}>
              <SortableItem index={index} item={item} />
            </React.Fragment>
          ))}
        </ul>
      );
    });

    return (
      <SortableList
        items={this.state.items}
        onSortEnd={this.onSortEnd}
        axis="y"
        helperClass="SortableHelper"
        key={this.props.key}
      />
    );
  }
}

export default SortableListPartners;
